import * as React from 'react';
import PropTypes from 'prop-types';
import {Link, useNavigate} from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import {
    AccountCircleOutlined,
    DescriptionOutlined,
    FastfoodOutlined,
    HomeOutlined,
    MapOutlined,
    TourOutlined,
    TvRounded
} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {setMobileOpen} from './redux/reducers/navSlice';
import Constant from './Constants';
import {IconButton, styled, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {deleteMyAccount, signInWithGoogle, signOut} from './redux/reducers/authSlice';
import {useState} from 'react';
import AlertDialog from './AlertDialog';

const drawerWidth = 240;

function FoodNav(props) {
    const {window} = props;

    const nav = useSelector(state => state.nav);
    const user = useSelector(state => state.auth.user);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
        dispatch(setMobileOpen(false))
    };

    const handleAuth = () => {
        if (user) {
            navigate('/my-food-lists')
        } else {
            confirmLogin()
        }
    }

    const confirmLogin = () => {
        setAlertTitle('Login')
        setAlertMessage('You are not logged in. Do you want to login/register? With an account you can save places to a custom list')
        setAlertOpen(true)
    }


    const handleAlertOpen = (didAccept) => {
        setAlertOpen(false)
        if (didAccept) {
            dispatch(signInWithGoogle())
        }
    }


    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        backgroundColor: Constant.appBarColor,
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));


    const drawer = (
        <Box sx={{backgroundColor: Constant.appBarColor}}>
            {alertOpen ?
                <AlertDialog open={alertOpen} title={alertTitle}
                             message={alertMessage}
                             handleClickOpen={handleAlertOpen}></AlertDialog> :
                null}
            <Toolbar/>
            <Divider/>
            <List>
                <ListItem key='home' onClick={() => handleDrawerToggle()} component={Link} to={"/keith-lee-food-list"}>
                    <ListItemIcon>{< HomeOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Home</ListItemText>
                </ListItem>
                <ListItem key='about' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/about-keith-lee-food-app"}>
                    <ListItemIcon>{< DescriptionOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>About</ListItemText>
                </ListItem>

                <ListItem key='foodlist' onClick={() => handleAuth()}>
                    <ListItemIcon>{< AccountCircleOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>
                        {user ? 'Your Account' : 'Login'}
                    </ListItemText>
                </ListItem>

                <ListItem key='foodtourarizona' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-arizona-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Arizona Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtouratlanta' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-atlanta-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Atlanta Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtourbatonrouge' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-baton-rouge-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Baton Rouge Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtourbay' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-bay-area-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Bay Area Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtourchicago' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-chicago-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Chicago Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtourdallas' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-dallas-tx-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Dallas Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtourdetroit' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-detroit-michigan-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Detroit Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtourhawaii' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-hawaii-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Hawaii Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtourhouston' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-houston-texas-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Houston Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtour' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Las Vegas Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodmap' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-food-map"}>
                    <ListItemIcon>{< MapOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Las Vegas Map</ListItemText>
                </ListItem>
                <ListItem key='vegasstripreviews' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-reviews-on-the-strip"}>
                    <ListItemIcon>{< TvRounded sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Las Vegas Strip</ListItemText>
                </ListItem>
                <ListItem key='foodtourlosangeles' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-los-angeles-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Los Angeles Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtourmiami' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-miami-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Miami Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtourlouisiana' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-louisiana-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>New Orleans Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtournewyork' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-new-york-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>New York Food Tour</ListItemText>
                </ListItem>
                <ListItem key='foodtourtoronto' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/keith-lee-toronto-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Toronto Food Tour</ListItemText>
                </ListItem>


            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    function handleDrawerClose() {
        dispatch(setMobileOpen(false))
    }

    return (
        <Box
            component="nav"
            sx={{
                width: {sm: drawerWidth}, flexShrink: {sm: 0},
            }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={nav.mobileOpen}
                onClose={handleDrawerToggle}
                // ModalProps={{
                //     keepMounted: true, // Better open performance on mobile.
                // }}
                sx={{
                    display: {xs: 'block', sm: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},

                }}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose} sx={{color: 'white'}}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </DrawerHeader>
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: {xs: 'none', sm: 'block'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth}, height: '100%'
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

FoodNav.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default FoodNav;
