import React, {useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import Constant from './Constants';
import ListItem from '@mui/material/ListItem';
import {Link} from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
    DescriptionOutlined,
    FastfoodOutlined,
    HomeOutlined,
    MapOutlined,
    TourOutlined,
    TvRounded
} from '@mui/icons-material';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';

const MapListDialog = (props) => {

    const handleOpen = () => {
        props.handleClickOpen(false)
    };

    return (
        <div>
            <Dialog aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleOpen}>
                    <h2>Choose a map</h2>
                </DialogTitle>
                <DialogContent dividers>
                    <List>
                        <ListItem key='arizona-foodtour' component={Link}
                                  to={"/keith-lee-arizona-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Arizona Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='atlanta-foodtour' component={Link}
                                  to={"/keith-lee-atlanta-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Atlanta Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='baton-rouge-foodtour' component={Link}
                                  to={"/keith-lee-baton-rouge-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Baton Rouge Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='foodtour' component={Link}
                                  to={"/keith-lee-bay-area-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Bay Area Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='chicago-foodtour' component={Link}
                                  to={"/keith-lee-chicago-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Chicago Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='foodtour' component={Link}
                                  to={"/keith-lee-dallas-tx-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Dallas Food Tour</ListItemText>
                        </ListItem>

                        <ListItem key='detroit-foodtour' component={Link}
                                  to={"/keith-lee-detroit-michigan-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Detroit Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='hawaii-foodtour' component={Link}
                                  to={"/keith-lee-hawaii-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Hawaii Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='houston-foodtour' component={Link}
                                  to={"/keith-lee-houston-texas-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Houston Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='foodtour' component={Link}
                                  to={"/keith-lee-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Las Vegas Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='los-angeles-foodtour' component={Link}
                                  to={"/keith-lee-los-angeles-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Los Angeles Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='louisiana-foodtour' component={Link}
                                  to={"/keith-lee-louisiana-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>New Orleans Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='miami-foodtour' component={Link}
                                  to={"/keith-lee-miami-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Miami Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='foodtour' component={Link}
                                  to={"/keith-lee-new-york-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>New York Food Tour</ListItemText>
                        </ListItem>
                        <ListItem key='foodtour' component={Link}
                                  to={"/keith-lee-toronto-food-tour"}>
                            <ListItemIcon>{< TourOutlined sx={{color: 'black'}}/>}</ListItemIcon>
                            <ListItemText sx={{color: 'black'}}>Toronto Food Tour</ListItemText>
                        </ListItem>

                    </List>
                </DialogContent>
                <DialogActions>
                    <Button sx={{color: Constant.appBarColor}} onClick={handleOpen} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>);
}

export default MapListDialog;
