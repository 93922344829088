import {Box} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter} from 'react-router-dom';
import React, {useEffect} from 'react';
import Toolbar from '@mui/material/Toolbar';
import FoodAppBar from './FoodAppBar';
import FoodRouter from './FoodRouter';
import FoodNav from './FoodNav';
import {backgroundColor} from './Constants';
import {supabase} from './supabaseClient';
import {getUserLists, getUserPlaces, loadUser, setUser} from './redux/reducers/authSlice';
import {useDispatch, useSelector} from 'react-redux';
import {getPlaces} from './redux/reducers/placesSlice';
import {getCategories} from './redux/reducers/categorySlice';
import FixedFooter from './FixedFooter';

const drawerWidth = 240;
const Main = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const places = useSelector(state => state.places.places);
    const categories = useSelector(state => state.categories.categories);
    const foodList = useSelector(state => state.auth.foodLists);

    useEffect(() => {
        const {data: authListener} = supabase.auth.onAuthStateChange(
            (event, session) => {
                handleEventAndSession(event, session)
            }
        );

        return () => {
            authListener.subscription.unsubscribe();
        };

        initData();


    }, []);

    const initData = async () => {

        if (user && foodList.length === 0) {
            await dispatch(getUserLists());
        }

        if (places.length === 0) {
            await dispatch(getPlaces())
        }

        if (categories.length === 0) {
            await dispatch(getCategories());
        }
    }

    const handleEventAndSession = async (event, session) => {
        if (event === 'SIGNED_IN' || event === 'INITIAL_SESSION') {
            if(session){
                dispatch(loadUser())
            }
        }
    }

    return (
        <Box sx={{display: 'flex',backgroundColor: backgroundColor}}>
            <CssBaseline/>
            <BrowserRouter>
                <FoodAppBar/>
                <FoodNav/>
                <Box
                    component="main"
                    sx={{flexGrow: 1, width: {sm: `calc(100% - ${drawerWidth}px)`},backgroundColor: backgroundColor}}
                >
                    <Toolbar/>
                    <FoodRouter/>
                </Box>
            </BrowserRouter>
        </Box>
    )
}
export default Main;

