import React from 'react';
import {Box, Card, CardContent, CardActionArea, Grid, Typography, Divider, Toolbar} from '@mui/material';
import {
    HomeOutlined,
    DescriptionOutlined,
    TourOutlined,
    MapOutlined,
    TvRounded,
    FastfoodOutlined
} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Constant from './Constants';
import ArizonaImage from './assets/arizona_words_white.png';
import AtlantaImage from './assets/atlanta_words_white.png';
import BayAreaImage from './assets/bay_area_words_white.png';
import ChicagoImage from './assets/chicago_words_white.png';
import DallasImage from './assets/dallas_words_white.png';
import DetroitImage from './assets/detroit_words_white.png';
import HoustonImage from './assets/houston_words_white.png';
import LasVegasImage from './assets/las_vegas_words_white.png';
import LosAngelesImages from './assets/los_angeles_words_white.png';
import LouisianaImage from './assets/louisiana_words_white.png';
import MiamiImage from './assets/miami_words.png';
import NewYorkImage from './assets/new_york_words_white.png';

// Define the menu items and their properties
const menuItems = [
    // { key: 'home', text: 'Food List', icon: <HomeOutlined />, link: '/keith-lee-food-list' },
    // { key: 'about', text: 'About', icon: <DescriptionOutlined />, link: '/about-keith-lee-food-app' },
    {
        key: 'foodtourarizona',
        text: 'Arizona Food Tour',
        icon: <img src={ArizonaImage} alt="Arizona" style={{width: '183px', height: '47px'}}/>,
        link: '/keith-lee-arizona-food-tour'
    },
    {
        key: 'foodtouratlanta',
        text: 'Atlanta Food Tour',
        icon: <img src={AtlantaImage} alt="Atlanta" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-atlanta-food-tour'
    },
    {
        key: 'foodtourbatonrouge',
        text: 'Baton Rouge Food Tour',
        icon: <TourOutlined/>,
        link: '/keith-lee-baton-rouge-food-tour'
    },
    {
        key: 'foodtourbay',
        text: 'Bay Area Food Tour',
        icon: <img src={BayAreaImage} alt="BayArea" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-bay-area-food-tour'
    },
    {
        key: 'foodtourchicago',
        text: 'Chicago Food Tour',
        icon: <img src={ChicagoImage} alt="Chicago" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-chicago-food-tour'
    },
    {
        key: 'foodtourdallas',
        text: 'Dallas Food Tour',
        icon: <img src={DallasImage} alt="Dallas" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-dallas-tx-food-tour'
    },
    {
        key: 'foodtourdetroit',
        text: 'Detroit Food Tour',
        icon: <img src={DetroitImage} alt="Detroit" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-detroit-michigan-food-tour'
    },
    {
        key: 'foodtourhouston',
        text: 'Houston Food Tour',
        icon: <img src={HoustonImage} alt="Houston" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-houston-texas-food-tour'
    },
    {
        key: 'foodtour',
        text: 'Las Vegas Food Tour',
        icon: <img src={LasVegasImage} alt="LasVegas" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-food-tour'
    },
    {
        key: 'foodmap',
        text: 'Las Vegas Map',
        icon: <img src={LasVegasImage} alt="LasVegasMap" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-food-map'
    },
    {
        key: 'vegasstripreviews',
        text: 'Las Vegas Strip',
        icon: <img src={LasVegasImage} alt="LasVegasMapStrip" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-reviews-on-the-strip'
    },
    {
        key: 'foodtourlosangeles',
        text: 'Los Angeles Food Tour',
        icon: <img src={LosAngelesImages} alt="LosAngeles" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-los-angeles-food-tour'
    },
    {
        key: 'foodtourmiami',
        text: 'Miami Food Tour',
        icon: <img src={MiamiImage} alt="Louisiana" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-miami-food-tour'
    },
    {
        key: 'foodtourlouisiana',
        text: 'New Orleans Food Tour',
        icon: <img src={LouisianaImage} alt="Louisiana" style={{width: '183px', height: '57px'}}/>,
        link: '/keith-lee-louisiana-food-tour'
    },
    {
        key: 'foodtourhawaii',
        text: 'Hawaii Food Tour',
        icon: <TourOutlined/>,
        link: '/keith-lee-hawaii-food-tour'
    },
    {
        key: 'foodtournewyork',
        text: 'New York Food Tour',
        icon: <img src={NewYorkImage} alt="NewYork" style={{width: '183px', height: '90px'}}/>,
        link: '/keith-lee-new-york-food-tour'},
    {
        key: 'foodtourtoronto',
        text: 'Toronto Food Tour',
        icon: <TourOutlined/>,
        link: '/keith-lee-toronto-food-tour'
    },
];

// Create a wrapper for the Link component
const LinkComponent = ({children, to, ...props}) => (
    <Link to={to} {...props}>
        {children}
    </Link>
);

const MenuGrid = () => {
    return (
        <Box sx={{backgroundColor: Constant.backgroundColor, height: '100vh', color: 'white', margin: '4px'}}>
            <Toolbar/>
            <Typography sx={{textAlign: 'center'}} variant="h5" component="div">
                Select an option below to begin your Keith Lee food tour.
            </Typography>
            <Divider/>
            <Grid container spacing={2} sx={{padding: 2, backgroundColor: Constant.backgroundColor,}}>
                {menuItems.map(item => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={item.key}>
                        <Card sx={{backgroundColor: 'black', color: 'white'}}>
                            <CardActionArea component={LinkComponent} to={item.link}>
                                <CardContent>
                                    <Typography sx={{display: 'flex', flexDirection: 'column', alignItems:'center'}} variant="h5" component="div">
                                        {item.icon}
                                    </Typography>
                                    <Typography sx={{display: 'flex', flexDirection: 'column', alignItems:'center'}} variant="h5" component="div">
                                        {item.text}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default MenuGrid;
