import {createSlice} from '@reduxjs/toolkit';
import {supabase} from '../../supabaseClient';

const initialState = {
    user: null,
    foodLists: [],
    foodListMap: {},
    listPlaces: [],
    listPlacesMap: {},
    placesToUse: [],
    loading: false,
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: state => {
            state.loading = true;
        },
        setUser: (state, action) => {
            state.user = action.payload;
            state.loading = false;
        },
        setUserLists: (state, action) => {
            state.foodLists = action.payload;
            state.loading = false;
            state.foodListMap = action.payload.reduce((acc, place) => {
                acc[place.id] = place;
                return acc;
            }, {})
        },
        setListPlaces: (state, action) => {
            state.listPlaces = action.payload;
            state.loading = false;
            state.listPlacesMap = action.payload.reduce((acc, place) => {
                acc[place.id] = place;
                return acc;
            }, {})
        },
        signOutUser: (state, action) => {
            state.user = null;
            state.loading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },

    }
});

export const {setLoading, setUser, setError, signOutUser, setUserLists, setListPlaces} = userSlice.actions;


export const signInWithGoogle = () => async dispatch => {
    const {data, error} = await supabase.auth.signInWithOAuth({
            provider: 'google'
        },
        {
            redirectTo: `${window.location.origin}/`
        })
}


export const deleteMyAccount = (userId) => async dispatch => {

    const {data, error} = await supabase.functions.invoke('user-self-delete', {
        // body: {deleteID: userId},
    })

}

export const signOut = () => async dispatch => {
    await supabase.auth.signOut()
    dispatch(signOutUser())
}
export const loadUser = () => async dispatch => {
    const {data: {user}, error} = await supabase.auth.getUser()
    if (!error) {
        const userObj = {
            name: user['user_metadata']['name'],
            userName: user['user_metadata']['userName'],
            uid: user.id,
            email: user.email,
            photoURL: user['user_metadata']['avatar_url']
        }
        dispatch(setUser(userObj))
    }
}

export const getUserLists = () => async dispatch => {

    const {data: {user}} = await supabase.auth.getUser()
    const {data, error} = await supabase.from('user_place_list')
        .select()
        .eq('user_id', user['id'])

    dispatch(setUserLists(data))
};

export const getUserPlacesFromList = (slug) => async dispatch => {
    const {data: {user}} = await supabase.auth.getUser()
    const {data, error} = await supabase.from('user_place')
        .select(
            `
            place_id (
                id,
                address,
                description,
                lat,
                lng,
                phone,
                video_id,
                order_url,
                name,
                rating,
                website_url,
                logo_url,
                slug,
                google_place_id,
                place_category ( id, category_id(name) )
            )`
        )
        .eq('user_id', user['id'])
        .eq('list_slug', slug)

    const placesInList = data.map((place) => {
        return place.place_id
    });

    dispatch(setListPlaces(placesInList))
};

export const updateCurrentUser = (firstName, lastName, userName) => async dispatch => {
    const {data, error} = await supabase.auth.updateUser({
        data: {
            firstName: firstName,
            lastName: lastName,
        }
    })
}

export const createPlaceList = (listName, userId) => async dispatch => {


    const {data, error} = await supabase
        .from('user_place_list')
        .insert(
            {
                user_id: userId,
                title: listName,
            }
        )
    dispatch(getUserLists());
};

export const addPlaceToList = (placeId, userId, list) => async dispatch => {
    const listId = list.id
    const listSlug = list.slug

    const placeList = await supabase
        .from('user_place')
        .select()
        .eq('user_id', userId)
        .eq('place_id', placeId)
        .eq('list_slug', listSlug)

    if (placeList.data && placeList.data.length === 0) {
        const {data, error} = await supabase
            .from('user_place')
            .insert(
                {
                    place_id: placeId,
                    user_id: userId,
                    place_list_id: listId,
                    list_slug: listSlug,
                }
            )
    }
};

export const deletePlaceFromList = (placeId, userId, slug) => async dispatch => {
    const {data, error} = await supabase
        .from('user_place')
        .delete()
        .eq('user_id', userId)
        .eq('place_id', placeId)
        .eq('list_slug', slug)

    dispatch(getUserPlacesFromList(slug))
};


export default userSlice.reducer;
