import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    FormControl,
    Grid, Grow,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {getBayPlacesOnTour, getPlacesOnTour, setCurrentPlace} from './redux/reducers/placesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {appBarColor, backgroundColor} from './Constants';
import SEO from './SEO';
import {useTheme} from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PlaceCardAction from './PlaceCardAction';
import Footer from './Footer';
import AddToFoodListDialog from './AddToFoodListDialog';
import AlertDialog from './AlertDialog';
import {signInWithGoogle} from './redux/reducers/authSlice';
import {MapOutlined} from '@mui/icons-material';
import MapListDialog from './MapListDialog';
import PlaceActionAccordion from './PlaceActionAccordion';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const BayFoodTour = () => {

    const dispatch = useDispatch();

    const [markers, setMarkers] = useState([]);
    const [placeCats, setPlaceCats] = useState([]);
    const [isApple, setIsApple] = useState(false);
    const [map, setMap] = useState(null);
    const params = useParams();
    let placesOnTour = useSelector(state => state.places.placesOnTour);
    const [selectedCategories, setTourCategories] = useState([]);
    const [generatedPlaces, setGeneratedPlaces] = useState([]);
    const [groupedPlaces, setGroupedPlaces] = useState({});
    const [mapOpen, setMapOpen] = useState(false);

    let google;

    const user = useSelector(state => state.auth.user);


    const theme = useTheme();

    const navigate = useNavigate();


    const [initialTourGenerated, setInitialTourGenerated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);


    useEffect(() => {

        if (!isLoading && placesOnTour.length > 0) {
            const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
            setIsApple(isAppleDevice);

            if (map != null) {
                setupMarkers()
            }

            const placeMap = {};

            const validCats = []
            placesOnTour.forEach((place) => {
                place.place_category.forEach((category) => {
                    const categoryName = category.category_id.name;
                    if (!placeMap[categoryName]) {
                        placeMap[categoryName] = [];
                        validCats.push(categoryName)
                    }
                    placeMap[categoryName].push(place);
                });
            });

            setGroupedPlaces(placeMap);
            setPlaceCats(validCats);

            if (!initialTourGenerated) {
                generateTour();
                setInitialTourGenerated(true); // Set flag to avoid regenerating the tour
            }
        }


    }, [placesOnTour, generatedPlaces, isLoading]);


    useEffect(() => {
        setupMap()
        initData()

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        initMapData()

    }, [map]);

    const handleMapListOpen = (open) => {
        setMapOpen(open)
    }

    const initMapData = async () => {
        if (map && placesOnTour.length > 0) {
            await setupMarkers()
        }
    }

    const initData = async () => {
        setIsLoading(true); // Data is being loaded
        await dispatch(getBayPlacesOnTour('CA'))
        setIsLoading(false); // Data is being loaded
    }

    const setupMap = async () => {
        google = window.google
        const {Map} = await google.maps.importLibrary("maps");

        setMap(new Map(document.getElementById("map"), {
                center: {lat: 37.4549, lng: -122.4194},
                zoom: 9.9,
            })
        )
    }

    const clearMarkers = async () => {
        markers.forEach((marker) => {
            marker.setMap(null)
            // marker = null
        })
        setMarkers([])
    }

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setTourCategories(
            typeof value === 'string' ? value.split(',') : value,
        )
    };


    const setCurrentMarker = (place) => {
        const position = {lat: +place.lat, lng: +place.lng};

        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);

        const marker = new google.maps.Marker({
            map: map,
            position: position,
            title: place.name,
            color: 'black',
            label: place.rating === '' ? null : {
                color: '#ffffff',
                fontWeight: 'bold',
                fontSize: '14px',
                text: place.rating
            },
            // label: { color: '#00aaff', fontWeight: 'bold', fontSize: '14px', text: 'Your text here' },
        });


        let directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}`;
        if (isAppleDevice) {
            directionsLink = `maps://?q=${place.lat},${place.lng}`;
        }

        const phone = place.phone;
        const callHref = `tel:${phone}`;

        const name = place.name;
        const address = place.address;
        const tiktokUrl = `https://www.tiktok.com/@keith_lee125/video/${place.video_id}`;
        // const shareHref = `mailto:?subject=let's eat at this restaurant&body=Hi, Keith Lee reviewed this restaurant! ${tiktokUrl}. Name: ${name}. Phone:${phone}. Address: ${address}`;
        const detailUrl = `https://keithleefood.com/place/${params.id}`;
        const shareHref = `mailto:?subject=let's eat at this restaurant&body=Hi, Keith Lee reviewed this restaurant! ${detailUrl}`;

        let categories = `<div></div>`;
        if (place.place_category)
            categories = `<i>${place.place_category.map((value) => value.category_id.name).join(", ")}</i>`;

        let callDiv = `<div></div>`
        if (place.phone !== '') {
            callDiv = `<hr>
         <div>
                 <a href="${callHref}" target="_blank"/>Call</a>
        </div>`;
        }

        let orderUrl = `<div></div>`
        if (place.order_url !== '') {
            orderUrl = `<hr>
         <div>
                <a href="${place.order_url}" target="_blank"/>Order</a>
        </div>`;
        }

        let websiteUrl = `<div></div>`
        if (place.website_url !== '') {
            websiteUrl = `<hr>
         <div>
                <a href="${place.website_url}" target="_blank"/>Website</a>
        </div>`;
        }


        let dir = `<div></div>`
        if (place.lat !== '') {
            dir = `<hr>
         <div>
                <a href="${directionsLink}" target="_blank"/>Directions</a>
        </div>`;
        }
        const information = new google.maps.InfoWindow({
            content: `
                         <h4>${place.name}</h4>
                             ${categories}
                         <hr>
                         <div>
                             ${address}
                         </div>
                         ${dir}
                         ${callDiv}
                         <hr>
                         <div>
                             <a href="${shareHref}" target="_blank"/>Share</a>
                         </div>
                         <hr>
                          <div>
                             <a href="${tiktokUrl}" target="_blank"/>Watch Review</a>
                         </div>
                         ${orderUrl}
                         ${websiteUrl}
                         `
        });

        marker.addListener('click', function () {
            information.open(map, marker);
        });

        return marker
    }

    const setupMarkers = async () => {

        if (map == null) {
            await setupMap();
            return;
        }

        await clearMarkers()

        google = window.google
        let currentMarkers = [];

        if (generatedPlaces.length > 0) {
            generatedPlaces.forEach((place) => {
                const marker = setCurrentMarker(place)
                currentMarkers.push(marker)
            });
        } else {
            placesOnTour.forEach((place) => {
                const marker = setCurrentMarker(place)
                currentMarkers.push(marker)
            });
        }

        setMarkers(currentMarkers)
    }


    const generateTour = async () => {
        await clearMarkers();

        let places;

        if (selectedCategories.length === 0) {
            // If no categories are selected, show all places
            places = [...placesOnTour];
        } else {
            // Otherwise, get places by selected categories
            places = getPlacesBySelectedCategories();
        }

        setGeneratedPlaces(places);
    }

    const resetCurrentTour = async () => {
        setTourCategories([])
        setGeneratedPlaces([...placesOnTour]);
    }
    const openPlaceDetails = (place) => {
        dispatch(setCurrentPlace(place))
        navigate(`/place/${place['slug']}`)
    }

    const getPlacesBySelectedCategories = () => {
        let filteredPlaces = [];

        const shuffleArray = (array) => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]]; // Swap elements
            }
            return array;
        };

        selectedCategories.forEach((category) => {
            if (groupedPlaces[category]) {
                const shuffledPlaces = shuffleArray([...groupedPlaces[category]]);
                const selectedPlace = shuffledPlaces[0]; // Take the first place after shuffling
                if (selectedPlace) {
                    filteredPlaces.push(selectedPlace);
                }
            }
        });

        return filteredPlaces;
    };


    function saveToList(place) {
        if (user) {
            setData(place)
            setOpen(true)
        } else {
            setAlertOpen(true)
        }

    }

    const handleAlertOpen = (didAccept) => {
        setAlertOpen(false)
        if (didAccept) {
            dispatch(signInWithGoogle())
        }
    }

    const handleListOpen = (open) => {
        setOpen(open)
    }

    const renderPlaceCard = (place) => {
        return (
            <Grid item xs={12} sm={6} md={4} key={place.id}>

                <Grow in={true}>
                    <Card>
                        <CardContent
                            onClick={() => openPlaceDetails(place)}
                        >
                            <Typography
                                sx={{fontSize: 14, width: '100%', textAlign: 'center'}}
                                color={appBarColor}
                                onClick={() => openPlaceDetails(place)}
                            >
                                <h3>{place.name !== undefined ? place.name : ''}</h3>
                            </Typography>
                            {place.logo_url ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img onClick={() => openPlaceDetails(place)} width="100px" height="100px"
                                         src={place.logo_url}/>
                                </Box>
                            ) : null}
                            {place.rating === '' ? null : (
                                <Typography
                                    sx={{fontSize: 13, width: '100%', textAlign: 'center'}}
                                    color={appBarColor}
                                    gutterBottom
                                >
                                    <p>
                                        <strong>Highest Rating: </strong>
                                        {place.rating}
                                    </p>
                                </Typography>
                            )}
                            <Typography
                                sx={{fontSize: 13, width: '100%', textAlign: 'center'}}
                                color={appBarColor}
                                gutterBottom
                            >
                                <div>
                                    {place.place_category !== undefined
                                        ? place.place_category
                                            .map((value) => value.category_id.name)
                                            .join(', ')
                                        : ''}
                                </div>
                            </Typography>
                            <Typography
                                sx={{fontSize: 14, fontStyle: 'italic', width: '100%', textAlign: 'center'}}
                                color="black"
                            >
                                <p>{place.description}</p>
                            </Typography>
                            <Typography sx={{fontSize: 14, textAlign: 'center'}} color="black" gutterBottom>
                                <p>{place.address !== undefined ? place.address : ''}</p>
                            </Typography>
                        </CardContent>
                        <Divider sx={{width: '100%'}}></Divider>
                        <CardActions>
                            <PlaceCardAction
                                index={place.slug}
                                place={place}
                                saveToList={() => saveToList(place)}
                                isApple={isApple}
                            />
                        </CardActions>
                    </Card>
                </Grow>
            </Grid>
        );
    };

    return <Box>
        <Box
            sx={{
                backgroundColor: backgroundColor,
                overflowX: 'hidden',
                minHeight: '100vh',
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <SEO
                title={`Keith Lee Bay Area CA Food Tour`}
                description={`Create your own custom Keith Lee Food tour!`}
                name={`Keith Lee food tour`}
                type={`article`}/>


            {open ?
                <AddToFoodListDialog data={data} open={open}
                                     handleClickOpen={handleListOpen}></AddToFoodListDialog> :
                null}

            {alertOpen ?
                <AlertDialog open={alertOpen} title="Save place"
                             message="Saving a place to a list requires creating an account. Would you like to sign up/login?"
                             handleClickOpen={handleAlertOpen}></AlertDialog> :
                null}

            {mapOpen ?
                <MapListDialog open={mapOpen} handleClickOpen={handleMapListOpen}></MapListDialog> :
                null}


            <Grow in={true}>
                <Card sx={{
                    maxWidth: 500,
                    width: '100%',
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "column",
                }}>

                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography gutterBottom variant="h5" component="div"
                                    sx={{padding: '5px', textAlign: 'center'}}>
                            Keith Lee Bay Area Food Tour
                        < /Typography>
                        <Typography variant="body2" color="black"
                                    sx={{padding: '5px', marginBottom: '10px', textAlign: 'center'}}>
                            Create your own custom Keith Lee Food tour!
                        </Typography>
                        <Typography variant="body2" color="black"
                                    sx={{padding: '2px', marginBottom: '5px', textAlign: 'center'}}>
                            Choose different categories and you will get a random place
                            from each category. This will give you a list and also update the map with the list.
                        </Typography>
                        <Typography variant="body2" color="black"
                                    sx={{padding: '5px', marginBottom: '5px', textAlign: 'center'}}>
                            You can also click the create food tour button to get a new random tour.
                        </Typography>
                        <FormControl sx={{width: '100%'}}>
                            <InputLabel id="demo-multiple-checkbox-label">Category</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedCategories}
                                onChange={handleChange}
                                input={<OutlinedInput label="Category"/>}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {/*{categories.map((category) => (*/}
                                {/*    <MenuItem key={category.id} value={category.name}>*/}
                                {/*        <Checkbox checked={selectedCategories.indexOf(category.name) > -1}/>*/}
                                {/*        <ListItemText primary={category.name}/>*/}
                                {/*    </MenuItem>*/}
                                {/*))}*/}
                                {placeCats.map((category, index) => (
                                    <MenuItem key={index} value={category}>
                                        <Checkbox checked={selectedCategories.indexOf(category) > -1}/>
                                        <ListItemText primary={category}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button variant="contained"
                                sx={{backgroundColor: "#222E50", padding: '5px', flexBasis: '45%', marginTop: '10px'}}
                                id={"generate-places"}
                                aria-label="map"
                                onClick={generateTour}
                        >
                            {/*<CreateOutlined/>*/}
                            Create Food Tour
                        </Button>
                        <Button variant="contained"
                                sx={{backgroundColor: "#222E50", padding: '5px', flexBasis: '45%', marginTop: '10px'}}
                                id={"reset-button"}
                                aria-label="reset tour"
                                onClick={resetCurrentTour}
                        >
                            {/*<LockReset/>*/}
                            Reset
                        </Button>

                        <Divider sx={{height: '10px', width: '100%', color: 'black'}}></Divider>

                        <PlaceActionAccordion
                            placeName={"the Bay Area"}
                            thingsToDoUrl={"https://expedia.com/affiliate/0pkhcaI"}
                            hotelsUrl={"https://expedia.com/affiliate/TK86BP7"}
                            eventsUrl={"https://expedia.com/affiliate/kiDhp7s"}
                            destinationId={4}
                        />

                        <Button variant="contained"
                                sx={{
                                    backgroundColor: "light-blue",
                                    padding: '5px',
                                    flexBasis: '45%',
                                    marginTop: '10px'
                                }}
                                id={"reset-button"}
                                aria-label="reset tour"
                                onClick={() => navigate('/keith-lee-los-angeles-food-tour')}
                        >
                            Los Angeles Food Tour
                        </Button>

                        <Divider sx={{height: '10px', width: '100%', color: 'black'}}></Divider>

                        <Button variant="contained"
                                sx={{
                                    backgroundColor: "#222E50",
                                    padding: '5px',
                                    marginTop: '10px'
                                }}
                                id={"list-map"}
                                aria-label="map"
                                onClick={() => handleMapListOpen(true)}
                        >
                            <MapOutlined/> All Maps
                        </Button>
                    </CardContent>
                </Card>
            </Grow>

            <Grid container spacing={2} sx={{marginTop: '10px'}}>
                {generatedPlaces.map((place) => renderPlaceCard(place))}
            </Grid>
            <Box
                sx={{
                    height: '100vh',
                    width: '100%',
                    marginTop: '10px',
                }}

                id="map"
            >

            </Box>
        </Box>
        {/*<Footer/>*/}
    </Box>

}

export default BayFoodTour

