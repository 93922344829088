import React from 'react';
import {Card, CardActions, CardContent, Divider, Grow, Typography} from '@mui/material';
import {appBarColor} from './Constants';
import ThingsToDoAction from './ThingsToDoAction';

const ThingsToDoCard = ({ place, index, isApple}) => (
    <Grow in={true}>
        <Card sx={{
            maxWidth: 500,
            width: '100%',
            marginTop: "10px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
        }}>
            <CardContent sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
            }}>
                <Typography
                    component={'span'}
                    sx={{ fontSize: 14, width: '100%', textAlign: 'center' }}
                    color={appBarColor}
                    id={index === 0 ? 'name-step' : `${index}-name-step`}
                    // onClick={() => openPlaceDetails(place)}
                >
                    <h3 key={place.id + place.description}>{place.name !== undefined ? place.name : ''}</h3>
                </Typography>
                {
                    place.logo_url ? <img
                        className='image'
                        width='100px'
                        height='100px'
                        src={place.logo_url}
                        // onClick={() => openPlaceDetails(place)}
                        loading="lazy"
                    /> : null
                }
                <Typography component={'span'} sx={{ fontSize: 14, fontStyle: 'italic' }} color="black">
                    <p>{place.description}</p>
                </Typography>
                <Typography component={'span'} sx={{ fontSize: 14 }} color="black" gutterBottom>
                    <p>{place.address !== undefined ? place.address : ''}</p>
                </Typography>
            </CardContent>
            <Divider sx={{ width: '100%' }}></Divider>
            <CardActions>
                <ThingsToDoAction index={index} place={place}
                                 isApple={isApple} />
            </CardActions>
        </Card>
    </Grow>
);

export default ThingsToDoCard;
