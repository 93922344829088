import {createSlice} from '@reduxjs/toolkit'
import {supabase} from '../../supabaseClient';
import {setTourCategories} from './categorySlice';
import WorkerFactory from '../../WorkerFactory';
import myWorker from '../../workers/myWorker.worker';

const initialState = {
    places: [],
    destinations: [],
    thingsToDo: [],
    filteredPlaces: [],
    placesMap: {},
    destination: {},
    currentPlace: null,
    currentPlaceFood: [],
    placesToUse: [],
    placesOnTheStrip: [],
    placesOnTour: [],
    placesOnTourOriginal: [],
    generatedPlaces: [],
    onStrip: false,
}

const placesSlice = createSlice({
    name: 'places',
    initialState,
    reducers: {
        setPlaces(state, action) {
            state.places = action.payload;
            state.placesToUse = action.payload;
            state.placesOnTour = action.payload;

            state.placesMap = action.payload.reduce((acc, place) => {
                acc[place.id] = place;
                return acc;
            }, {})

        },
        setCurrentPlace(state, action) {
            state.currentPlace = action.payload;
        },
        setPlacesOnStrip(state, action) {
            state.placesOnTheStrip = action.payload;
        },
        setOnStrip(state, action) {
            state.onStrip = action.payload;
        },
        setCurrentFood(state, action) {
            state.currentPlaceFood = action.payload;
        },
        setFilteredPlaces(state, action) {
            state.filteredPlaces = action.payload;
            state.placesToUse = action.payload;
        },
        setPlacesOnTour(state, action) {
            state.placesOnTour = action.payload;
        },
        setOriginalPlacesOnTour(state, action) {
            state.placesOnTourOriginal = action.payload;
            state.generatedPlaces = [];
        },
        setGeneratedPlaces(state, action) {
            state.generatedPlaces = action.payload;
        },
        resetPlaces(state, action) {
            state.placesToUse = state.places;
        },
        resetPlacesOnTour(state, action) {
            state.placesOnTour = state.placesOnTourOriginal;
        },
        setDestinations(state, action) {
            state.destinations = action.payload;
        },
        setDestination(state, action) {
            state.destination = action.payload;
        },
        setThingsToDo(state, action) {
            state.thingsToDo = action.payload;
        },
    }
})

export const {
    setPlaces,
    setDestinations,
    setDestination,
    setThingsToDo,
    setFilteredPlaces,
    resetPlaces,
    resetPlacesOnTour,
    setCurrentPlace,
    setCurrentFood,
    setPlacesOnStrip,
    setPlacesOnTour,
    setOriginalPlacesOnTour,
    setGeneratedPlaces,
    setOnStrip
} = placesSlice.actions

const workerInstance = new WorkerFactory(myWorker);

export const getDestination = (destinationId) => async dispatch => {
    const {data, error} = await supabase.from('destination')
        .select()
        .eq('id', destinationId)
        .order('name')


    if (!error && data[0])
        await dispatch(setDestination(data[0]))
}

export const getDestinations = () => async dispatch => {
    const {data, error} = await supabase.from('destination')
        .select(
            `
            id,
            name
       `
        )
        .order('name')

    if (!error)
        await dispatch(setDestinations(data))
}

export const getThingsToDo = (destinationId) => async dispatch => {
    const {data, error} = await supabase.from('things_to_do')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            name,
            rating,
            website_url,
            logo_url,
            on_vegas_strip,
            google_place_id,
            slug,
            city,
            state
       `
        )
        .eq('destination_id', destinationId)
        .order('name')

    if (!error)
        await dispatch(setThingsToDo(data))
}

export const getPlaces = () => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            on_vegas_strip,
            google_place_id,
            slug,
            city,
            state,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')

    await dispatch(setPlaces(data))
}

export const getCurrentPlace = (slug) => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            city,
            state,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            on_vegas_strip,
            slug,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .eq('slug', slug)
    await dispatch(setCurrentPlace(data[0]))
}

export const getPlacesOnTheStrip = () => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            on_vegas_strip,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')
        .eq('on_vegas_strip', true)
    await dispatch(setPlacesOnStrip(data))
}

export const getLAPlacesOnTour = (state) => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            on_vegas_strip,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')
        .eq('state', state)
        .neq('city', 'Oakland')
        .neq('city', 'San Francisco')
        .neq('city', 'SF')
        .order('name')

    await dispatch(setPlacesOnTour(data))
    await dispatch(setOriginalPlacesOnTour(data))
    await dispatch(setTourCategories([]))
}

export const getBayPlacesOnTour = (state) => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            on_vegas_strip,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')
        .eq('state', state)
        .neq('city', 'Los Angeles')
        .neq('city', 'Inglewood')
        .neq('city', 'Burbank')
        .neq('city', 'Santa Monica')
        .neq('city', 'Alhambra')
        .neq('city', 'Fullerton')


    await dispatch(setPlacesOnTour(data))
    await dispatch(setOriginalPlacesOnTour(data))
    await dispatch(setTourCategories([]))
}

export const getDallasPlacesOnTour = (state) => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            on_vegas_strip,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')
        .eq('state', state)
        .neq('city', 'Houston')
        .neq('city', 'Pearland')


    await dispatch(setPlacesOnTour(data))
    await dispatch(setOriginalPlacesOnTour(data))
    await dispatch(setTourCategories([]))
}

export const getHoustonPlacesOnTour = (state) => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            on_vegas_strip,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')
        .eq('state', state)
        .neq('city', 'Dallas')
        .neq('city', 'Frisco')
        .neq('city', 'Fort Worth')
        .neq('city', 'Farmers Branch')
        .neq('city', 'DeSoto')
        .neq('city', 'Garland')
        .neq('city', 'Rowlett')


    await dispatch(setPlacesOnTour(data))
    await dispatch(setOriginalPlacesOnTour(data))
    await dispatch(setTourCategories([]))
}

export const getNewOrleansPlacesOnTour = () => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            on_vegas_strip,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')
        .neq('city', 'Baton Rouge')
        .eq('state', 'LA')


    await dispatch(setPlacesOnTour(data))
    await dispatch(setOriginalPlacesOnTour(data))
    await dispatch(setTourCategories([]))
}

export const getBatonRougePlacesOnTour = () => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            on_vegas_strip,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')
        .eq('state', 'LA')
        .eq('city', 'Baton Rouge')
        .neq('city', 'New Orleans')
        .neq('city', 'St Rose')


    await dispatch(setPlacesOnTour(data))
    await dispatch(setOriginalPlacesOnTour(data))
    await dispatch(setTourCategories([]))
}

export const getPlacesOnTour = (state) => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            on_vegas_strip,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')
        .eq('state', state)
    await dispatch(setPlacesOnTour(data))
    await dispatch(setOriginalPlacesOnTour(data))
    await dispatch(setTourCategories([]))
}

export const getPlacesOnTourByCountry = (country) => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            on_vegas_strip,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')
        .eq('country', country)
    await dispatch(setPlacesOnTour(data))
    await dispatch(setOriginalPlacesOnTour(data))
    await dispatch(setTourCategories([]))
}


export const getFoodFromPlace = (placeId) => async dispatch => {
    const {data, error} = await supabase.from('food')
        .select()
        .eq('place_id', placeId)
    await dispatch(setCurrentFood(data))
}


export const updateFilteredCategories = (category, places, onVegasStrip) => async dispatch => {

    const filteredPlaces = await places.filter((place) => {
        if (place.place_category.length === 0) return false
        for (let i = 0; i < place.place_category.length; i++) {
            const placeCategory = place.place_category[i];
            if (placeCategory.category_id.name.toLowerCase() === category.toLowerCase() || category.toLowerCase() === 'all') {
                if (onVegasStrip) {
                    return place.on_vegas_strip;
                } else {
                    return true;
                }
            }
        }
        return false
    })

    // filter out the places that do have the category using placesObject
    // const filteredPlaces = await places.filter((place) => {
    //     if (placesObject[place.id]) return true
    //     return false
    // })

    await dispatch(setFilteredPlaces(filteredPlaces))
}

export const updateFilteredCategoriesState = (category, places, onVegasStrip, selectedLocation = 'all') => async dispatch => {

    const data = {
        category: category,
        places: places,
        onVegasStrip,
        selectedLocation
    }
    workerInstance.postMessage(data);

    // Listen for messages from the worker
    workerInstance.onmessage = async (res) => {
        await dispatch(setFilteredPlaces(res.data));
        // dispatch(hideLoader()); // Hide loading symbol
    };

    // Handle errors from the worker
    workerInstance.onerror = (err) => {
        // dispatch(hideLoader()); // Hide loading symbol
    };

};


export const resetPlacesToUse = () => async dispatch => {
    await dispatch(resetPlaces())
}
export const resetTour = () => async dispatch => {
    await dispatch(resetPlacesOnTour())
}

export default placesSlice.reducer
