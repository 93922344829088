import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';
import PlaceCardAction from './PlaceCardAction';
import {appBarColor} from './Constants';
import {setCurrentPlace} from './redux/reducers/placesSlice';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom'; // Adjust the import based on your project structure

const PlaceCard = ({place, saveToList}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isApple, setIsApple] = useState(false);

    useEffect(() => {
            const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
            setIsApple(isAppleDevice);
    }, []);

    const openPlaceDetails = (place) => {
        dispatch(setCurrentPlace(place))
        navigate(`/place/${place['slug']}`)
    }

    return (
        <Grid item xs={12} sm={6} md={4} key={place.id}>
            <Grow in={true}>
                <Card>
                    <CardContent onClick={() => openPlaceDetails(place)}>
                        <Typography
                            sx={{ fontSize: 14, width: '100%', textAlign: 'center' }}
                            color={appBarColor}
                        >
                            <h3>{place.name !== undefined ? place.name : ''}</h3>
                        </Typography>
                        {place.logo_url ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <img width="100px" height="100px" src={place.logo_url} alt="Place logo" />
                            </Box>
                        ) : null}
                        {place.rating === '' ? null : (
                            <Typography
                                sx={{ fontSize: 13, width: '100%', textAlign: 'center' }}
                                color={appBarColor}
                                gutterBottom
                            >
                                <p>
                                    <strong>Highest Rating: </strong>
                                    {place.rating}
                                </p>
                            </Typography>
                        )}
                        <Typography
                            sx={{ fontSize: 13, width: '100%', textAlign: 'center' }}
                            color={appBarColor}
                            gutterBottom
                        >
                            <div>
                                {place.place_category !== undefined
                                    ? place.place_category
                                        .map((value) => value.category_id.name)
                                        .join(', ')
                                    : ''}
                            </div>
                        </Typography>
                        <Typography
                            sx={{ fontSize: 14, fontStyle: 'italic', width: '100%', textAlign: 'center' }}
                            color="black"
                        >
                            <p>{place.description}</p>
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="black" gutterBottom>
                            <p>{place.address !== undefined ? place.address : ''}</p>
                        </Typography>
                    </CardContent>
                    <Divider sx={{ width: '100%' }} />
                    <CardActions>
                        <PlaceCardAction
                            index={place.slug}
                            place={place}
                            isApple={isApple}
                            saveToList={() => saveToList(place)}
                        />
                    </CardActions>
                </Card>
            </Grow>
        </Grid>
    );
};

export default PlaceCard;
