import {Box, Card, CardActionArea, CardContent, Typography, useMediaQuery} from '@mui/material';
import Divider from '@mui/material/Divider';
import SEO from './SEO';
import React, {useEffect, useState} from 'react';
import {backgroundColor} from './Constants';
import {useTheme} from '@mui/material/styles';
import {useParams} from 'react-router-dom';

const ThingsToDo = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const { cityId } = useParams();
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        const loadActivities = async () => {
            try {
                let data;
                switch (cityId) {
                    case 'vegas':
                        data = await import('./data/vegasActivities');
                        break;
                    case 'la':
                        data = await import('./data/laActivities');
                        break;
                    case 'chicago':
                        data = await import('./data/chicagoActivities');
                        break;
                    case 'houston':
                        data = await import('./data/houstonActivities');
                        break;
                    case 'dallas':
                        data = await import('./data/dallasActivities');
                        break;
                    case 'phoenix':
                        data = await import('./data/phoenixActivities');
                        break;
                    case 'toronto':
                        data = await import('./data/torontoActivities');
                        break;
                    case 'the-bay-area':
                        data = await import('./data/sanFranciscoActivities');
                        break;
                    case 'detroit':
                        data = await import('./data/detroitActivities');
                        break;
                    case 'hawaii':
                        data = await import('./data/hawaiiActivities');
                        break;
                    case 'atlanta':
                        data = await import('./data/atlantaActivities');
                        break;
                    case 'miami':
                        data = await import('./data/miamiActivities');
                        break;
                    case 'new-york':
                        data = await import('./data/newYorkActivities');
                        break;
                    case 'new-orleans':
                        data = await import('./data/newOrleansActivities');
                        break;
                    case 'baton-rouge':
                        data = await import('./data/batonRougeActivities');
                        break;
                    // Add more cases for other cities
                    default:
                        data = { default: [] };
                }
                setActivities(data.default);
            } catch (error) {
                console.error('Error loading activities:', error);
            }
        };

        loadActivities();
    }, [cityId]);

    return <Box>
        <Box sx={{
            maxWidth: '100%',
            minHeight: '100vh',
            backgroundColor: backgroundColor,
            marginRight: matches ? '10px' : '250px',
            marginLeft: matches ? '10px' : '250px',
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex',
            flexDirection: 'column',
            color: "black",
        }}>
            <SEO
                title={`Things to do in ${cityId.toUpperCase().replace(/-/g, ' ')}`}
                description={`Things to do in ${cityId.toUpperCase().replace(/-/g, ' ')}`}
                name={`Keith Lee Food App`}
                type={`article`}/>
            <Card sx={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
            }}>
                <CardContent
                    sx={{
                        // height: '100vh',
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-evenly",
                        // alignItems: "center",
                    }}
                >
                    <Typography gutterBottom variant="h5" component="div" sx={{padding: '5px'}}>
                        Things to do in {cityId.toUpperCase().replace(/-/g, ' ')}
                    < /Typography>

                    <Divider sx={{height: '1px', width: '100%'}}></Divider>
                    {activities.map((activity, index) => (
                        <React.Fragment key={index}>
                            <Typography variant="h5" sx={{ padding: '5px' }}>
                                <div>{activity.title}</div>
                            </Typography>

                            <Typography variant="body2" sx={{ marginBottom: '5px' }}>
                                <p>{activity.description}</p>
                                <a href={activity.link} target="_blank" rel="noopener noreferrer">
                                    {activity.linkText}
                                </a>
                            </Typography>

                            {index < activities.length - 1 && <Divider />} {/* Add Divider between items, but not after the last item */}
                        </React.Fragment>
                    ))}
                </CardContent>
            </Card>
        </Box>
        {/*<Footer/>*/}
    </Box>

}

export default ThingsToDo;
