import {Box, Button, Card, CardContent, Typography, useMediaQuery} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {getPlacesOnTheStrip} from './redux/reducers/placesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {backgroundColor} from './Constants';
import SEO from './SEO';
import {useTheme} from '@mui/material/styles';
import Footer from './Footer';
import Divider from '@mui/material/Divider';
import {MapOutlined} from '@mui/icons-material';
import MapListDialog from './MapListDialog';
import PlaceActionAccordion from './PlaceActionAccordion';

const PlacesOnStrip = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [markers, setMarkers] = useState([]);
    const [isApple, setIsApple] = useState(false);
    const [map, setMap] = useState(null);
    const params = useParams();
    let placesOnTheStrip = useSelector(state => state.places.placesOnTheStrip);
    let google;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const [mapOpen, setMapOpen] = useState(false);


    useEffect(() => {
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
        setIsApple(isAppleDevice);

        initMapData()

    }, [placesOnTheStrip]);


    useEffect(() => {
        setupMap()
        initData()

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        initMapData()

    }, [map]);

    const handleMapListOpen = (open) => {
        setMapOpen(open)
    }

    const initData = async () => {
        if (placesOnTheStrip == null || placesOnTheStrip.length === 0) {
            await dispatch(getPlacesOnTheStrip())
        }
    }

    const initMapData = async () => {
        if (placesOnTheStrip.length > 0) {
            await setupMarkers()
        }
    }

    const setupMap = async () => {
        google = window.google
        const {Map} = await google.maps.importLibrary("maps");

        setMap(new Map(document.getElementById("map"), {
                center: {lat: 36.088110, lng: -115.176468},
                zoom: 11.9,
            })
        )
    }

    const clearMarkers = async () => {
        markers.forEach((marker) => {
            marker.setMap(null)
            // marker = null
        })
        setMarkers([])
    }
    const setupMarkers = async () => {
        if (map == null) {
            await setupMap();
            return;
        }

        await clearMarkers()

        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);

        google = window.google
        let currentMarkers = [];
        placesOnTheStrip.forEach((place) => {
            const position = {lat: +place.lat, lng: +place.lng};

            const marker = new google.maps.Marker({
                map: map,
                position: position,
                title: place.name,
                color: 'black',
                label: place.rating === '' ? null : {
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    text: place.rating
                },
                // label: { color: '#00aaff', fontWeight: 'bold', fontSize: '14px', text: 'Your text here' },
            });


            let directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}`;
            if (isAppleDevice) {
                directionsLink = `maps://?q=${place.lat},${place.lng}`;
            }

            const phone = place.phone;
            const callHref = `tel:${phone}`;

            const name = place.name;
            const address = place.address;
            const tiktokUrl = `https://www.tiktok.com/@keith_lee125/video/${place.video_id}`;
            // const shareHref = `mailto:?subject=let's eat at this restaurant&body=Hi, Keith Lee reviewed this restaurant! ${tiktokUrl}. Name: ${name}. Phone:${phone}. Address: ${address}`;
            const detailUrl = `https://keithleefood.com/place/${params.id}`;
            const shareHref = `mailto:?subject=let's eat at this restaurant&body=Hi, Keith Lee reviewed this restaurant! ${detailUrl}`;

            let categories = `<div></div>`;
            if (place.place_category)
                categories = `<i>${place.place_category.map((value) => value.category_id.name).join(", ")}</i>`;

            let callDiv = `<div></div>`
            if (place.phone !== '') {
                callDiv = `<hr>
         <div>
                 <a href="${callHref}" target="_blank"/>Call</a>
        </div>`;
            }

            let orderUrl = `<div></div>`
            if (place.order_url !== '') {
                orderUrl = `<hr>
         <div>
                <a href="${place.order_url}" target="_blank"/>Order</a>
        </div>`;
            }

            let websiteUrl = `<div></div>`
            if (place.website_url !== '') {
                websiteUrl = `<hr>
         <div>
                <a href="${place.website_url}" target="_blank"/>Website</a>
        </div>`;
            }


            let dir = `<div></div>`
            if (place.lat !== '') {
                dir = `<hr>
         <div>
                <a href="${directionsLink}" target="_blank"/>Directions</a>
        </div>`;
            }
            const information = new google.maps.InfoWindow({
                content: `
                         <h4>${place.name}</h4>
                             ${categories}
                         <hr>
                         <div>
                             ${address}
                         </div>
                         ${dir}
                         ${callDiv}
                         <hr>
                         <div>
                             <a href="${shareHref}" target="_blank"/>Share</a>
                         </div>
                         <hr>
                          <div>
                             <a href="${tiktokUrl}" target="_blank"/>Watch Review</a>
                         </div>
                         ${orderUrl}
                         ${websiteUrl}
                         `
            });

            marker.addListener('click', function () {
                information.open(map, marker);
            });
            currentMarkers.push(marker)
        });

        setMarkers(currentMarkers)

    }


    return <Box>
        <Box
            sx={{
                backgroundColor: backgroundColor,
                overflowX: 'hidden',
                minHeight: '100vh',
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <SEO
                title={`Keith Lee Las Vegas Strip Reviews`}
                description={`Go on a Keith Lee food tour on the Las Vegas Strip!`}
                name={`Keith Lee food app`}
                type={`article`}/>

            {mapOpen ?
                <MapListDialog open={mapOpen} handleClickOpen={handleMapListOpen}></MapListDialog> :
                null}

            <Card sx={{
                maxWidth: 500,
                width: '100%',
                marginTop: "10px",
                marginBottom: "10px",
                display: "flex",
                flexDirection: "column",
            }}>
                <CardContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography gutterBottom variant="h5" component="div" sx={{padding: '5px', textAlign: 'center'}}>
                        Las Vegas Strip food reviews
                    < /Typography>
                    <Typography variant="body2" color="text.secondary"
                                sx={{padding: '5px', marginBottom: '10px', textAlign: 'center'}}>
                        Keith Lee has reviewed many places on or close to the strip.
                    </Typography>

                    <Divider sx={{height: '10px', width: '100%', color: 'black'}}></Divider>

                    <PlaceActionAccordion
                        placeName={"Las Vegas"}
                        thingsToDoUrl={"https://expedia.com/affiliate/Wza0zET"}
                        hotelsUrl={"https://expedia.com/affiliate/OdV5rQt"}
                        eventsUrl={"https://expedia.com/affiliate/pzPxfT8"}
                        destinationId={1}
                    />

                    <Divider sx={{height: '10px', width: '100%', color: 'black'}}></Divider>

                    <Button variant="contained"
                            sx={{
                                backgroundColor: "#222E50",
                                padding: '5px',
                                marginTop: '10px'
                            }}
                            id={"list-map"}
                            aria-label="map"
                            onClick={() => handleMapListOpen(true)}
                    >
                        <MapOutlined/> All Maps
                    </Button>

                </CardContent>
            </Card>
            {/*{ map ? <div></div> : <LoadingAnimation/>}*/}

            <Box
                sx={{
                    height: '100vh',
                    width: '100%'
                }}

                id="map"
            >

            </Box>
        </Box>
        {/*<Footer/>*/}
    </Box>

}

export default PlacesOnStrip
