import {AccordionDetails, AccordionSummary, Button, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import {useNavigate} from 'react-router-dom';

const PlaceActionAccordion = ({title, thingsToDoUrl, hotelsUrl, eventsUrl, placeName, destinationId}) => {
    const navigate = useNavigate()

    const navigateToThingsToDo = ()  =>{
        const url = thingsToDoUrl
        // window.open(url, '_blank', 'noreferrer');
        navigate('/things-to-do-in/' + destinationId)
    }

    const navigateToHotels = ()  =>{
        const url = hotelsUrl
        window.open(url, '_blank', 'noreferrer');
    }

    const navigateToLiveEvents = ()  =>{
        const url = eventsUrl
        window.open(url, '_blank', 'noreferrer');
    }

    return  <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
                backgroundColor: "#2D66BF",
                color: 'white'
            }}
        >
            <Typography>Things to do, discounts, and more</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Button variant="contained"
                    sx={{
                        backgroundColor: "light-blue",
                        padding: '5px',
                        flexBasis: '45%',
                        width: '100%',
                        marginTop: '10px'
                    }}
                    id={"reset-button"}
                    aria-label="reset tour"
                    onClick={() => navigateToThingsToDo()}
            >
                Things to do in {placeName}
            </Button>
            <Button variant="contained"
                    sx={{
                        backgroundColor: "light-blue",
                        padding: '5px',
                        flexBasis: '45%',
                        width: '100%',
                        marginTop: '10px'
                    }}
                    id={"reset-button"}
                    aria-label="reset tour"
                    onClick={() => navigateToHotels()}
            >
                Hotel discounts in {placeName}
            </Button>
            <Button variant="contained"
                    sx={{
                        backgroundColor: "light-blue",
                        padding: '5px',
                        flexBasis: '45%',
                        width: '100%',
                        marginTop: '10px'
                    }}
                    id={"reset-button"}
                    aria-label="reset tour"
                    onClick={() => navigateToLiveEvents()}
            >
                Live Events in {placeName}
            </Button>
            {/*<ExpediaWidget/>*/}
        </AccordionDetails>
    </Accordion>;

}

export default PlaceActionAccordion;
