import './App.css';
import React, {useEffect, useState} from "react";
import {useTheme} from '@mui/material/styles';
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardActions,
    CardContent, Grow,
    Typography,
    useMediaQuery
} from "@mui/material";
import {appBarColor, backgroundColor} from "./Constants";
import {useDispatch, useSelector} from 'react-redux';
import {Call, Directions, EmailRounded, Fastfood, SaveOutlined, Tv, Web} from '@mui/icons-material';
import {useParams} from 'react-router-dom';
import {signInWithGoogle} from './redux/reducers/authSlice';
import AlertDialog from './AlertDialog';
import {getCurrentPlace, getFoodFromPlace} from './redux/reducers/placesSlice';
import LoadingAnimation from './LoadingAnimation';
import AddToFoodListDialog from './AddToFoodListDialog';
import Divider from '@mui/material/Divider';
import PlaceCardActionItem from './PlaceCardActionItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SEO from './SEO';
import Footer from './Footer';


const Place = () => {
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [isApple, setIsApple] = useState(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const params = useParams();

    let place = useSelector(state => state.places.currentPlace);
    let foodList = useSelector(state => state.places.currentPlaceFood);
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        // Check if the user is on an Apple device
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
        setIsApple(isAppleDevice);
        initData()
        window.scrollTo(0, 0);
    }, [place]);


    const initData = async () => {

        if (place == null) {
            await dispatch(getCurrentPlace(params.id))
        }
        if (place) {
            await dispatch(getFoodFromPlace(place.id))
        }
    }

    function watchVideo() {
        window.open(`https://www.tiktok.com/@keith_lee125/video/${place.video_id}`, '_blank', 'noreferrer');
    }

    const orderFood = () => {
        window.open(place.order_url, '_blank', 'noreferrer');
    }

    const openWebsite = () => {
        window.open(place.website_url, '_blank', 'noreferrer');
    }

    const callPlace = () => {
        const phone = place.phone;
        const href = `tel:${phone}`;
        window.open(href);
    }
    const emailPlace = () => {
        const name = place.name;
        const phone = place.phone;
        const address = place.address;
        const tiktokUrl = `https://www.tiktok.com/@keith_lee125/video/${place.video_id}`;
        const detailUrl = `https://keithleefood.com/place/${params.id}`;
        const href = `mailto:?subject=let's eat at this restaurant&body=Hi, Keith Lee reviewed this restaurant! ${detailUrl}`;
        window.open(href);
    }

    function goToMap() {
        if (isApple) {
            window.open(`maps://?q=${place.lat},${place.lng}`);
        } else {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}`);
        }
    }

    function saveToList() {
        if (user) {
            setData(place)
            setOpen(true)
        } else {
            setAlertOpen(true)
        }

    }

    const handleAlertOpen = (didAccept) => {
        setAlertOpen(false)
        if (didAccept) {
            dispatch(signInWithGoogle())
        }
    }

    const handleListOpen = (open) => {
        setOpen(open)
    }


    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: backgroundColor,
                    // overflowX: 'hidden',
                    minHeight: '100%',
                    height: '100%',
                    marginRight: matches ? '10px' : '250px',
                    marginLeft: matches ? '10px' : '250px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                {place ? <SEO
                    title={`${place.name} - Keith Lee Food Review`}
                    description={`Keith Lee reviewed ${place.name} in ${place.city}, ${place.state}`}
                    name={`${place.name} review`}
                    type={`article`}/> : null
                }

                {open ?
                    <AddToFoodListDialog data={data} open={open}
                                         handleClickOpen={handleListOpen}></AddToFoodListDialog> :
                    null}

                {alertOpen ?
                    <AlertDialog open={alertOpen} title="Save place"
                                 message="Saving a place to a list requires creating an account. Would you like to sign up/login?"
                                 handleClickOpen={handleAlertOpen}></AlertDialog> :
                    null}

                {place !== null ?

                    <Grow in={true}>
                        <Card key={place.id} sx={{
                            maxWidth: 500,
                            width: '100%',
                            marginTop: "10px",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <CardContent sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                            }}>

                                <Typography sx={{fontSize: 14, width: '100%', textAlign: 'center'}} color={appBarColor}>
                                    <h3 key={place.id + place.description}>{place.name !== undefined ? place.name : ''}</h3>
                                </Typography>
                                {place.logo_url ?
                                    <img className='image' width='100px' height='100px' src={place.logo_url}/> : null}
                                {
                                    place.rating === '' ? null :
                                        <Typography sx={{fontSize: 13, width: '100%', textAlign: 'center'}}
                                                    color={appBarColor}
                                                    gutterBottom>
                                            <strong>Highest Rating: </strong>{place.rating}
                                        </Typography>
                                }
                                <Typography sx={{fontSize: 13, width: '100%', textAlign: 'center'}} color={appBarColor}
                                            gutterBottom>
                                    <div
                                        key={place.id + place.description + place.name}>{place.place_category !== undefined ? place.place_category.map((value) => value.category_id.name).join(", ") : null}</div>
                                </Typography>

                                <Typography sx={{fontSize: 14, fontStyle: 'italic', width: '100%', textAlign: 'center'}}
                                            color="black">
                                    <p>{place.description}</p>
                                </Typography>
                                <Typography sx={{fontSize: 14}} color="black" gutterBottom>
                                    <p>{place.address !== undefined ? place.address : ''}</p>
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Box
                                    sx={{
                                        width: '100%',
                                        maxWidth: '100%',
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-evenly",
                                    }}
                                >

                                    <Divider sx={{width: '100%'}}></Divider>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >

                                        <PlaceCardActionItem icon={<SaveOutlined sx={{color: appBarColor,}}/>}
                                                             title={'Save'}
                                                             action={() => saveToList()}/>

                                        {
                                            place.lat !== '' ?
                                                <PlaceCardActionItem icon={<Directions sx={{color: appBarColor,}}/>}
                                                                     title={'Let\'s Go!'}
                                                                     action={() => goToMap()}/> : null
                                        }


                                        <PlaceCardActionItem icon={<Tv sx={{color: appBarColor,}}/>}
                                                             title={'Watch Review'}
                                                             action={() => watchVideo()}/>

                                        {
                                            place.order_url !== '' ?
                                                <PlaceCardActionItem icon={<Fastfood sx={{color: appBarColor,}}/>}
                                                                     title={'Order'} action={() => orderFood()}/> : null
                                        }

                                    </Box>
                                </Box>

                            </CardActions>
                        </Card>
                    </Grow>

                    : <Box sx={{height: '100vh'}}>
                        <LoadingAnimation/>
                    </Box>
                }
                {place !== null ?
                    <Card
                        sx={{
                            marginBottom: '10px',
                            maxWidth: 500,
                            width: '100%',
                            display: "flex",
                            justifyContent: 'space-evenly'
                        }}
                    >
                        <CardContent sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            width: '100%',
                        }}
                        >

                            <PlaceCardActionItem icon={<Call sx={{color: appBarColor,}}/>} title={'Call'}
                                                 action={() => callPlace()}/>
                            {place['website_url'] ?
                                <PlaceCardActionItem icon={<Web sx={{color: appBarColor,}}/>} title={'Website'}
                                                     action={() => openWebsite()}/> : null}
                            <PlaceCardActionItem icon={<EmailRounded sx={{color: appBarColor,}}/>} title={'Share'}
                                                 action={() => emailPlace()}/>
                        </CardContent>
                    </Card> : null}
                {foodList && foodList.length === 0 ? null :
                    <Card
                        sx={{
                            marginBottom: '10px',
                            maxWidth: 500,
                            width: '100%',
                        }}
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {
                                    foodList && foodList.length === 0 ? <div></div> :
                                        foodList.map((food) => {
                                            return <Box>
                                                <Typography sx={{fontSize: 14}} color="black" gutterBottom>
                                                    <div><strong>{food.name}</strong></div>
                                                    <div>Rating: {food.rating}</div>
                                                </Typography>
                                                <Divider></Divider>
                                            </Box>

                                        })
                                }
                            </Box>
                        </CardContent>
                    </Card>
                }

                {place !== null && place.video_id !== null && place.video_id !== undefined && place.video_id !== '' ?
                    <Box sx={{width: '100%', marginBottom: '10px'}}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Watch Video</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '600px'
                                    }}
                                >
                                    <iframe width="100%" height="615"
                                            src={`https://www.tiktok.com/embed/${place.video_id}`}
                                            title="Keith Lee food review" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen>
                                    </iframe>

                                    <script async src="https://www.tiktok.com/embed.js"></script>
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                    </Box>
                    : null
                }

                {/*{place !== null && place.youtube_video_id !== null && place.youtube_video_id !== undefined && place.youtube_video_id !== '' ?*/}
                {/*    <Box sx={{width: '100%', marginBottom: '10px'}}>*/}
                {/*        <Accordion>*/}
                {/*            <AccordionSummary*/}
                {/*                expandIcon={<ExpandMoreIcon/>}*/}
                {/*                aria-controls="panel1a-content"*/}
                {/*                id="panel1a-header"*/}
                {/*            >*/}
                {/*                <Typography>Watch Video</Typography>*/}
                {/*            </AccordionSummary>*/}
                {/*            <AccordionDetails>*/}
                {/*                <iframe width="100%" height="415"*/}
                {/*                        src={`https://www.youtube.com/embed/${place.youtube_video_id}`}*/}
                {/*                        title="YouTube video player" frameBorder="0"*/}
                {/*                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
                {/*                        allowFullScreen>*/}
                {/*                </iframe>*/}
                {/*            </AccordionDetails>*/}
                {/*        </Accordion>*/}

                {/*    </Box>*/}
                {/*    : null*/}
                {/*}*/}

                {place !== null && place.google_place_id !== null ? <Box sx={{width: '100%'}}>
                    <iframe width="100%" height="450" frameborder="0"
                            src={`https://www.google.com/maps/embed/v1/place?q=place_id:${place.google_place_id}&key=${process.env.REACT_APP_MAPS_KEY}`}
                            allowFullScreen>
                    </iframe>
                </Box> : null}
            </Box>
            {/*<Footer/>*/}
        </Box>
    );
}

export default Place;
