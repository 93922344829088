import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {getDestination, getThingsToDo} from './redux/reducers/placesSlice';
import {Box, Card, CardContent, Typography, useMediaQuery} from '@mui/material';
import {backgroundColor} from './Constants';
import LoadingAnimation from './LoadingAnimation';
import ThingsToDoCard from './ThingsToDoCard';
import {useTheme} from '@mui/material/styles';

const DestinationThingsToDo = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const theme = useTheme()

    const matches = useMediaQuery(theme.breakpoints.down('lg'))
    const destination = useSelector(state => state.places.destination)

    const thingsToDo = useSelector(state => state.places.thingsToDo)


    useEffect(() => {
        window.scrollTo(0, 0)

        dispatch(getDestination(params.destinationId))
        dispatch(getThingsToDo(params.destinationId))

    }, []);


    return <Box
        sx={{
            backgroundColor: backgroundColor,
            overflowX: 'hidden',
            minHeight: '100vh',
            marginRight: matches ? '10px' : '250px',
            marginLeft: matches ? '10px' : '250px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Card sx={{
            maxWidth: 500,
            width: '100%',
            marginTop: "10px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
        }}>

            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography gutterBottom variant="h5" component="div"
                            sx={{padding: '5px', textAlign: 'center'}}>
                   Things to do in {destination ? destination.name : ''}
                < /Typography>
                <Typography variant="body2" color="black"
                            sx={{padding: '5px', marginBottom: '10px', textAlign: 'center'}}>
                    Find new experiences and things to do in {destination ? destination.name : ''}
                </Typography>
            </CardContent>
        </Card>

        {thingsToDo.length !== 0 ?

            thingsToDo.map((place, index) => (
                <ThingsToDoCard key={index} index={index} place={place}/>
            ))
            : <LoadingAnimation/>
        }

    </Box>

}

export default DestinationThingsToDo;
