import './App.css';
import React, {useEffect, useState} from "react";
import {useTheme} from '@mui/material/styles';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent, Checkbox, Fade,
    FormControl, FormControlLabel, Grow,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    useMediaQuery
} from "@mui/material";
import {appBarColor, backgroundColor} from "./Constants";
import {
    getPlaces,
    resetPlacesToUse,
    setCurrentPlace,
    setOnStrip,
    updateFilteredCategories, updateFilteredCategoriesState
} from './redux/reducers/placesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {getCategories, setSelectedCategory} from './redux/reducers/categorySlice';
import {MapOutlined} from '@mui/icons-material';
import {Link, useNavigate, useParams} from 'react-router-dom';
import LoadingAnimation from './LoadingAnimation';
import AddToFoodListDialog from './AddToFoodListDialog';
import {getUserLists, signInWithGoogle} from './redux/reducers/authSlice';
import AlertDialog from './AlertDialog';
import PlaceCardAction from './PlaceCardAction';
import Divider from '@mui/material/Divider';
import SEO from './SEO';
import Footer from './Footer';
import MapListDialog from './MapListDialog';
import ScrollToTop from 'react-scroll-to-top';


function Places() {
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [mapOpen, setMapOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertRemoveOpen, setAlertRemoveOpen] = useState(false);
    const [isApple, setIsApple] = useState(false);
    const onStrip = useSelector(state => state.places.onStrip);

    const theme = useTheme();
    const params = useParams();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const categories = useSelector(state => state.categories.categories);
    const foodList = useSelector(state => state.auth.foodLists);
    const user = useSelector(state => state.auth.user);
    const places = useSelector(state => state.places.places);
    const placesMap = useSelector(state => state.places.placesMap);
    const placesToUse = useSelector(state => state.places.placesToUse);
    const selectedCategory = useSelector(state => state.categories.selectedCategory);

    const locations = [
        'All',
        'Arizona',
        'Atlanta',
        'Bay Area',
        'Chicago',
        'Dallas',
        'Detroit',
        'Hawaii',
        'Houston',
        'Las Vegas',
        'Los Angeles',
        'Louisiana',
        'Miami',
        'New York',
        // ... add any other locations as needed
    ];

    const [selectedLocation, setSelectedLocation] = useState('All');


    useEffect(() => {
        if (places.length === 0) {
            dispatch(getPlaces())
        }

        if (categories.length === 0) {
            dispatch(getCategories());
        }

        // Check if the user is on an Apple device
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
        setIsApple(isAppleDevice);
    }, []);


    useEffect(() => {

        if (user) {
            dispatch(getUserLists());
        }

        // Check if the user is on an Apple device
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
        setIsApple(isAppleDevice);
    }, [user]);

    const handleChange = async (event) => {
        await dispatch(setSelectedCategory(event.target.value))
        // check if the location is not las vegas, if not set onStrip to false
        let isOnStrip = onStrip
        if (selectedLocation !== 'Las Vegas' || selectedLocation !== 'All') {
            isOnStrip = false
            await dispatch(setOnStrip(false))
        }
        await dispatch(updateFilteredCategoriesState(event.target.value, places, isOnStrip, selectedLocation))
    };

    const handleCheckChange = async (event) => {
        const checked = event.target.checked;
        await dispatch(setOnStrip(checked))
        await dispatch(updateFilteredCategoriesState(selectedCategory, places, checked, selectedLocation))
    };

    function saveToList(place) {
        if (user) {
            setData(place)
            setOpen(true)
        } else {
            setAlertOpen(true)
        }

    }

    const handleListOpen = (open) => {
        setOpen(open)
    }

    const handleMapListOpen = (open) => {
        setMapOpen(open)
    }

    const handleAlertOpen = (didAccept) => {
        setAlertOpen(false)
        if (didAccept) {
            dispatch(signInWithGoogle())
        }
    }

    const handleAlertRemoveOpen = (didAccept) => {
        setAlertRemoveOpen(false)
        if (didAccept) {
            // dispatch(signInWithGoogle())
        }
    }

    const openPlaceDetails = (place) => {
        dispatch(setCurrentPlace(place))
        navigate(`/place/${place['slug']}`)
    }

    const handleLocationChange = async (event) => {
        const newLocation = event.target.value;
        // Perform additional processing here
        // Example: Fetch data related to the selected location, validate selection, etc.

        // Finally, update the state with the new location
        setSelectedLocation(newLocation);

        // check if the location is not las vegas, if not set onStrip to false
        let isOnStrip = onStrip
        if (selectedLocation !== 'Las Vegas' || selectedLocation !== 'All') {
            isOnStrip = false
            await dispatch(setOnStrip(false))
        }


        await dispatch(updateFilteredCategoriesState(selectedCategory, places, isOnStrip, newLocation))
    };


    return (
        <Box
            sx={{
                backgroundColor: backgroundColor,
                overflowX: 'hidden',
                minHeight: '100vh',
                marginRight: matches ? '10px' : '250px',
                marginLeft: matches ? '10px' : '250px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >

            <SEO
                title={`Keith Lee Food Review List`}
                description={`List of Keith Lee's food reviews. Take a Keith Lee food tour`}
                name={`Keith Lee Review List`}
                type={`article`}/>

            <ScrollToTop smooth color="#1B9AAA" />

            {open ?
                <AddToFoodListDialog data={data} open={open} handleClickOpen={handleListOpen}></AddToFoodListDialog> :
                null}


            {mapOpen ?
                <MapListDialog open={mapOpen} handleClickOpen={handleMapListOpen}></MapListDialog> :
                null}

            {alertOpen ?
                <AlertDialog open={alertOpen} title="Save place"
                             message="Saving a place to a list requires creating an account. Would you like to sign up/login?"
                             handleClickOpen={handleAlertOpen}></AlertDialog> :
                null}

            {alertRemoveOpen ?
                <AlertDialog open={alertRemoveOpen} title="Remove place from list"
                             message="Would you like to remove this place from the list?"
                             handleClickOpen={handleAlertRemoveOpen}></AlertDialog> :
                null}

            <Grow in={true}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        maxWidth: 500,
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            flexWrap: 'nowrap',
                            margin: '5px',
                        }}
                    >
                        {selectedLocation === 'Las Vegas' ?
                            <FormControl
                                id={"vegas-strip-filter"}
                                sx={{
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    padding: '5px',
                                    flexBasis: selectedLocation === 'Las Vegas' ? '45%' : '0%', // Adjust basis based on condition
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={onStrip}
                                            onChange={handleCheckChange}
                                        />
                                    }
                                    label="Las Vegas Strip"
                                />
                            </FormControl> : null
                        }

                        <Button variant="contained"
                                sx={{
                                    backgroundColor: "#222E50",
                                    padding: '5px',
                                    flexBasis: selectedLocation === 'Las Vegas' ? '45%' : '100%', // Make button full width when Las Vegas filter is not shown
                                }}
                                id={"list-map"}
                                aria-label="map"
                                onClick={() => handleMapListOpen(true)}
                            // component={Link} to={"/keith-lee-food-map"}
                        >
                            <MapOutlined/> Map
                        </Button>
                    </Box>



                    <FormControl sx={{marginTop: '15px', backgroundColor: 'white', borderRadius: '10px', width: '100%'}}
                                 fullWidth>
                        <InputLabel
                            sx={{color: 'black', backgroundColor: 'white', borderRadius: '10px', padding: '3px'}}>
                            Category
                        </InputLabel>
                        <Select
                            sx={{
                                borderRadius: '10px',
                            }}
                            inputProps={{
                                MenuProps: {
                                    MenuListProps: {
                                        sx: {}
                                    }
                                }
                            }}
                            labelId="demo-simple-select-label"
                            id="category-filter"
                            value={selectedCategory}
                            label="Category"
                            onChange={handleChange}
                        >
                            <MenuItem value='All'>All</MenuItem>
                            {categories.map(category => {
                                return (
                                    <MenuItem key={category.id} value={category.name}>
                                        {category.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{marginTop: '15px', backgroundColor: 'white', borderRadius: '10px', width: '100%'}}
                                 fullWidth>
                        <InputLabel
                            sx={{color: 'black', backgroundColor: 'white', borderRadius: '10px', padding: '3px'}}>
                            Location
                        </InputLabel>
                        <Select
                            labelId="location-select-label"
                            id="location-select"
                            value={selectedLocation}
                            label="Location"
                            onChange={handleLocationChange}
                            sx={{borderRadius: '10px'}}
                        >
                            {locations.map((location, index) => (
                                <MenuItem key={index} value={location}>
                                    {location}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grow>

            {placesToUse.length !== 0 ?

                placesToUse.map((place, index) => (
                    <Grow key={place.id} in={true}>
                        <Card  sx={{
                            maxWidth: 500,
                            width: '100%',
                            marginTop: "10px",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <CardContent sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                            }}>
                                <Typography
                                    component={'span'}
                                    sx={{fontSize: 14, width: '100%', textAlign: 'center'}}
                                    color={appBarColor}
                                    id={index === 0 ? 'name-step' : `${index}-name-step`}
                                    onClick={() => openPlaceDetails(place)}
                                >
                                    <h3 key={place.id + place.description}>{place.name !== undefined ? place.name : ''}</h3>
                                </Typography>
                                {

                                    place.logo_url ? <img
                                        className='image'
                                        width='100px'
                                        height='100px'
                                        src={place.logo_url}
                                        onClick={() => openPlaceDetails(place)}
                                        loading="lazy"
                                    /> : null
                                }
                                {
                                    place.rating === '' ? null :
                                        <Typography component={'span'} sx={{fontSize: 13, width: '100%', textAlign: 'center'}}
                                                    color={appBarColor} gutterBottom>
                                            <p><strong>Highest Rating: </strong>{place.rating}</p>
                                        </Typography>
                                }
                                <Typography component={'span'} sx={{fontSize: 13, width: '100%', textAlign: 'center'}} color={appBarColor}
                                            gutterBottom>
                                    <div
                                        key={place.id + place.description + place.name}>{place.place_category !== undefined ? place.place_category.map((value) => value.category_id.name).join(", ") : ''}</div>
                                </Typography>
                                <Typography component={'span'} sx={{fontSize: 14, fontStyle: 'italic'}} color="black">
                                    <p>{place.description}</p>
                                </Typography>
                                <Typography component={'span'} sx={{fontSize: 14}} color="black" gutterBottom>
                                    <p>{place.address !== undefined ? place.address : ''}</p>
                                </Typography>
                            </CardContent>
                            <Divider sx={{width: '100%'}}></Divider>
                            <CardActions>
                                <PlaceCardAction index={index} place={place} saveToList={() => saveToList(place)}
                                                 isApple={isApple}/>
                            </CardActions>
                        </Card>
                    </Grow>
                ))
                : <LoadingAnimation/>
            }
            {/*{placesToUse.length !== 0 ? <Footer/> : null}*/}

        </Box>
    );
}

export default Places;
