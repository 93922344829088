import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    Grid,
    IconButton,
    Typography,
    useMediaQuery
} from '@mui/material';
import Divider from '@mui/material/Divider';
import SEO from './SEO';
import React, {useEffect, useState} from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Footer from './Footer';
import {backgroundColor} from './Constants';
import {useTheme} from '@mui/material/styles';
import {MapOutlined} from '@mui/icons-material';
import MapListDialog from './MapListDialog';


const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const [mapOpen, setMapOpen] = useState(false);

    const handleMapListOpen = (open) => {
        setMapOpen(open)
    }

    const contributors = [
        {name: 'Christan', linkedIn: 'https://www.linkedin.com/in/christan-price-52b37963/'},
        {name: 'Andrew', linkedIn: 'https://www.linkedin.com/in/andrew-wilkinson-9450b9277/'},
        {name: 'Chris', linkedIn: 'https://www.linkedin.com/in/cmcoffeedev/'},
    ];

    return <Box>
        <Box sx={{
            maxWidth: '100%',
            minHeight: '100vh',
            backgroundColor: backgroundColor,
            marginRight: matches ? '10px' : '250px',
            marginLeft: matches ? '10px' : '250px',
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex',
            flexDirection: 'column',
            color: "black",
        }}>
            <SEO
                title={`About the Keith Lee App`}
                description={`Keith Lee is a food critic that reviews restaurants across America in places such as Vegas, LA, Atlanta, Detroit, Chicago, Louisiana, and New York.`}
                name={`Keith Lee Food Critic`}
                type={`article`}/>

            {mapOpen ?
                <MapListDialog open={mapOpen} handleClickOpen={handleMapListOpen}></MapListDialog> :
                null}

            <Card sx={{
                width: '100%',
                display: "flex",
                // justifyContent: 'space-evenly',
                flexDirection: "column",
            }}>
                <CardContent
                    sx={{
                        // height: '100vh',
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-evenly",
                        // alignItems: "center",
                    }}
                >
                    <Typography gutterBottom variant="h5" component="div" sx={{padding: '5px'}}>
                        About
                    < /Typography>
                    <Typography variant="body2">
                        <p>Everyone trusts Keith Lee's
                            taste in food.
                            After each review, businesses get a rush of customers and sell
                            out within hours. You can follow him everywhere @keith_lee125
                        </p>
                        <a href='https://linktr.ee/Keith_lee125'
                           target='_blank' rel="noreferrer">Keith Lee Link Tree
                        </a>
                    </Typography>
                    <Typography variant="body2">
                        <p>Consider watching Keith Lee's reviews to to find the best
                            places to eat in Las Vegas, Los Angeles, Detroit, Chicago, Atlanta, New York, Bay Area, Houston and Louisiana
                        </p>

                    </Typography>
                    <Divider sx={{height: '1px', width: '100%'}}></Divider>
                    <Typography variant="h5" sx={{padding: '5px'}}>
                        <div>Install the Keith Lee food app</div>
                    </Typography>
                    <Typography variant="body2">
                        <p>Since this is a PWA (progressive web app), you can add an app icon to your phone. Similar to
                            a native
                            app that you download from the App and Play store</p>
                        <p>To install the Keith Lee food app on iOS, click <strong>Share</strong>, <strong>Add To Home
                            Screen</strong></p>
                        <p>To install the Keith Lee food app on Android, click <strong>Settings(3
                            dots)</strong>, <strong>Install
                            App</strong></p>
                    </Typography>

                    {/*<Divider sx={{height: '1px', width: '100%'}}></Divider>*/}

                    {/*<Typography variant="h5" sx={{padding: '5px'}}>*/}
                    {/*    <div>Contributors</div>*/}
                    {/*</Typography>*/}
                    {/*<Grid container spacing={2} justifyContent="center">*/}
                    {/*    {contributors.map((contributor, index) => (*/}
                    {/*        <Grid item xs={4} sm={4} key={index}>*/}
                    {/*            <Typography variant="subtitle1" gutterBottom sx={{textAlign: 'center'}}>*/}
                    {/*                {contributor.name}*/}
                    {/*            </Typography>*/}
                    {/*            <Box sx={{textAlign: 'center'}}>*/}
                    {/*                <IconButton color="primary" aria-label={`LinkedIn of ${contributor.name}`}*/}
                    {/*                            href={contributor.linkedIn} target="_blank" rel="noopener noreferrer">*/}
                    {/*                    <LinkedInIcon/>*/}
                    {/*                </IconButton>*/}
                    {/*            </Box>*/}
                    {/*        </Grid>*/}
                    {/*    ))}*/}
                    {/*</Grid>*/}
                    <br/>
                    <Divider sx={{height: '1px', width: '100%'}}></Divider>
                    <Typography variant="h5" sx={{padding: '5px'}}>
                        <div>Feature Requests and Bug Reporting</div>
                    </Typography>
                    <Typography variant="body2" sx={{marginBottom: '5px'}}>
                        <p>Use the following form to request new features and/or report issues.</p>
                        <a href="https://forms.gle/btDJceAxj4CjK24eA" target={'_blank'}>
                            Feature Requests/Bug Reporting form
                        </a>
                    </Typography>
                    <Divider/>
                    <Button variant="contained"
                            sx={{
                                backgroundColor: "#222E50",
                                padding: '5px',
                                marginTop: '10px'
                            }}
                            id={"list-map"}
                            aria-label="map"
                            onClick={() => handleMapListOpen(true)}
                    >
                        <MapOutlined/> All Maps
                    </Button>
                    <Divider/>
                </CardContent>
            </Card>
        </Box>
        {/*<Footer/>*/}
    </Box>

}

export default About;
