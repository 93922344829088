import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    useMediaQuery
} from "@mui/material";
import {useDispatch, useSelector} from 'react-redux';
import {
    getPlaces,
    resetPlaces,
    resetPlacesToUse,
    setOnStrip,
    updateFilteredCategories
} from './redux/reducers/placesSlice';
import {getCategories, setSelectedCategory} from './redux/reducers/categorySlice';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {MapOutlined} from '@mui/icons-material';
import {useTheme} from '@mui/material/styles';
import LoadingAnimation from './LoadingAnimation';
import SEO from './SEO';
import Footer from './Footer';
import MapListDialog from './MapListDialog';
import Divider from '@mui/material/Divider';
import PlaceActionAccordion from './PlaceActionAccordion';

const FoodMap = () => {

    const [markers, setMarkers] = useState([]);
    const [mapOpen, setMapOpen] = useState(false);
    const [isApple, setIsApple] = useState(false);
    const [map, setMap] = useState(null);
    const dispatch = useDispatch();
    const params = useParams();
    const places = useSelector(state => state.places.places);
    const placesToUse = useSelector(state => state.places.placesToUse);
    const onStrip = useSelector(state => state.places.onStrip);
    const categories = useSelector(state => state.categories.categories);
    const selectedCategory = useSelector(state => state.categories.selectedCategory);
    let google;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();


    useEffect(() => {
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
        setIsApple(isAppleDevice);

        if (map != null && placesToUse.length > 0) {
            setupMarkers()
        }
    }, [placesToUse, map]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setupMap()
        initData()

    }, []);

    const handleMapListOpen = (open) => {
        setMapOpen(open)
    }

    const setupMap = async () => {
        google = window.google
        const {Map} = await google.maps.importLibrary("maps");

        setMap(new Map(document.getElementById("map"), {
                center: {lat: 36.088110, lng: -115.176468},
                zoom: 11.9,
            })
        )
    }


    const initData = async () => {
        // if (places.length === 0) {
        await dispatch(getPlaces());
        // }

        if (categories.length === 0) {
            await dispatch(getCategories());
        }

    }

    const clearMarkers = async () => {
        markers.forEach((marker) => {
            marker.setMap(null)
            marker = null
        })
        setMarkers([])
    }

    const handleChange = async (event) => {
        await dispatch(setSelectedCategory(event.target.value))
        // if (event.target.value === 'All') {
        //     await dispatch(resetPlacesToUse())
        // } else {
        await dispatch(updateFilteredCategories(event.target.value, places, onStrip))
        // }
    };


    const handleCheckChange = async (event) => {
        const checked = event.target.checked;
        await dispatch(setOnStrip(checked))
        await dispatch(updateFilteredCategories(selectedCategory, places, checked))
    };


    const setupMarkers = async () => {
        if (map == null) {
            await setupMap();
            return;
        }
        await clearMarkers()

        // const{data, error} = await supabase.from('places').select()
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);

        google = window.google
        let currentMarkers = [];
        placesToUse.forEach((place) => {
            const position = {lat: +place.lat, lng: +place.lng};

            const marker = new google.maps.Marker({
                map: map,
                position: position,
                title: place.name,
                color: 'black',
                label: place.rating === '' ? null : {
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    text: place.rating
                },
                // label: { color: '#00aaff', fontWeight: 'bold', fontSize: '14px', text: 'Your text here' },
            });


            let directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}`;
            if (isAppleDevice) {
                directionsLink = `maps://?q=${place.lat},${place.lng}`;
            }

            const phone = place.phone;
            const callHref = `tel:${phone}`;

            const name = place.name;
            const address = place.address;
            const tiktokUrl = `https://www.tiktok.com/@keith_lee125/video/${place.video_id}`;
            // const shareHref = `mailto:?subject=let's eat at this restaurant&body=Hi, Keith Lee reviewed this restaurant! ${tiktokUrl}. Name: ${name}. Phone:${phone}. Address: ${address}`;
            const detailUrl = `https://keithleefood.com/place/${params.id}`;
            const shareHref = `mailto:?subject=let's eat at this restaurant&body=Hi, Keith Lee reviewed this restaurant! ${detailUrl}`;

            let categories = `<div></div>`;
            if (place.place_category)
                categories = `<i>${place.place_category.map((value) => value.category_id.name).join(", ")}</i>`;

            let callDiv = `<div></div>`
            if (place.phone !== '') {
                callDiv = `<hr>
         <div>
                 <a href="${callHref}" target="_blank"/>Call</a>
        </div>`;
            }

            let orderUrl = `<div></div>`
            if (place.order_url !== '') {
                orderUrl = `<hr>
         <div>
                <a href="${place.order_url}" target="_blank"/>Order</a>
        </div>`;
            }

            let image = ``;
            if (place.logo_url) image = `<img src="${place.logo_url}" width="100px" height="100px"/>`;

            let websiteUrl = `<div></div>`
            if (place.website_url !== '') {
                websiteUrl = `<hr>
         <div>
                <a href="${place.website_url}" target="_blank"/>Website</a>
        </div>`;
            }


            let dir = `<div></div>`
            if (place.lat !== '') {
                dir = `<hr>
         <div>
                <a href="${directionsLink}" target="_blank"/>Directions</a>
        </div>`;
            }
            const information = new google.maps.InfoWindow({
                content: `
                         <h4>${place.name}</h4>
                            ${image}
                         <hr>
                             ${categories}
                         <hr>
                         <div>
                             ${address}
                         </div>
                         ${dir}
                         ${callDiv}
                         <hr>
                         <div>
                             <a href="${shareHref}" target="_blank"/>Share</a>
                         </div>
                         <hr>
                          <div>
                             <a href="${tiktokUrl}" target="_blank"/>Watch Review</a>
                         </div>
                         ${orderUrl}
                         ${websiteUrl}
                         `
            });

            marker.addListener('click', function () {
                information.open(map, marker);
            });
            currentMarkers.push(marker)
        });

        setMarkers(currentMarkers)

    }


    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            alignItems: 'center',
            // width: '100%'
        }}

    >

        <SEO
            title={`Keith Lee review map`}
            description={`Map of Keith Lee reviewed places in Las Vegas. Check out the video and see if you would try it out.`}
            name={`Keith Lee food app map`}
            type={`article`}/>

        {mapOpen ?
            <MapListDialog open={mapOpen} handleClickOpen={handleMapListOpen}></MapListDialog> :
            null}

        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: 500,
                width: '100%',
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: 'nowrap',
                    margin: '5px',
                }}
            >
                <FormControl
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        // flexBasis: 'auto',
                        // flexBasis: '47%',
                        padding: '5px'
                    }}
                >
                    <FormControlLabel

                        control={
                            <Checkbox
                                checked={onStrip}
                                onChange={handleCheckChange}
                            />
                        }
                        label="Las Vegas Strip"
                    />
                </FormControl>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#222E50",
                        flexBasis: '45%',
                        // flexBasis: 'auto',
                    }}
                    aria-label="list"
                    component={Link}
                    to={"/keith-lee-food-list"}
                >
                    <MapOutlined/> List
                </Button>

            </Box>


            <FormControl
                sx={{
                    backgroundColor: 'white',
                    marginTop: '10px',
                    marginBottom: '10px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    // maxWidth: '90%',
                    borderRadius: '10px',
                }}

            >
                <InputLabel sx={{backgroundColor: 'white', color: 'black', borderRadius: '10px', padding: '3px'}}>
                    Category
                </InputLabel>

                <Select
                    sx={{
                        borderRadius: '10px',
                        // backgroundColor: 'white'
                    }}
                    inputProps={{
                        MenuProps: {
                            MenuListProps: {
                                sx: {
                                    backgroundColor: 'white'
                                }
                            }
                        }
                    }}
                    // sx={{
                    //     backgroundColor: 'orange',
                    //     color: 'black'
                    // }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCategory}
                    label="Category"
                    onChange={handleChange}
                >
                    <MenuItem value='All'>All</MenuItem>
                    {categories.map(category => {
                        return (
                            <MenuItem key={category.id} value={category.name}>
                                {category.name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>

            <PlaceActionAccordion
                placeName={"Las Vegas"}
                thingsToDoUrl={"https://expedia.com/affiliate/Wza0zET"}
                hotelsUrl={"https://expedia.com/affiliate/OdV5rQt"}
                eventsUrl={"https://expedia.com/affiliate/pzPxfT8"}
                destinationId={1}
            />

            <Divider sx={{height: '10px', width: '100%', color: 'black'}}></Divider>

            <Button variant="contained"
                    sx={{
                        backgroundColor: "#222E50",
                        padding: '5px',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}
                    id={"list-map"}
                    aria-label="map"
                    onClick={() => handleMapListOpen(true)}
            >
                <MapOutlined/> All Maps
            </Button>

        </Box>

        {map ? <div></div> : <LoadingAnimation/>}

        <Box
            sx={{
                // display: 'flex',
                // flexDirection: 'column',
                height: '100%',
                // justifyContent: 'space-between'
                width: '90%'
            }}

            id="map"
        >

        </Box>
        {/*<Footer/>*/}

    </Box>


}

export default FoodMap;
