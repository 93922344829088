import {Box, IconButton, Menu, MenuItem, Typography} from '@mui/material';
import {AirplaneTicket, Call, Directions, Fastfood, MoreHorizRounded, SaveOutlined, Tv, Web} from '@mui/icons-material';
import {appBarColor} from './Constants';
import {setCurrentPlace} from './redux/reducers/placesSlice';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import PlaceCardActionItem from './PlaceCardActionItem';

const ThingsToDoAction = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const place = props.place;

    const [isApple, setIsApple] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        // Check if the user is on an Apple device
        setIsApple(props.isApple);
    }, []);


    const openPlaceDetails = () => {
        dispatch(setCurrentPlace(place))
        navigate(`/place/${place['slug']}`)
    }

    const openWebsite = (url) => {
        window.open(url, '_blank', 'noreferrer');
    }

    const callPlace = () => {
        const phone = place.phone;
        const href = `tel:${phone}`;
        window.open(href);
    }

    const goToMap = () => {
        if (isApple) {
            window.open(`maps://?q=${place.lat},${place.lng}`);
        } else {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}`);
        }
    }

    return <Box
        sx={{
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
        }}
    >

        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >

            {place['website_url'] ? <PlaceCardActionItem id={props.index === 0 ? 'web-step' : `web${props.index}`}
                                                         icon={<Web sx={{color: appBarColor,}}/>} title={'Website'}
                                                         action={() => openWebsite(place['website_url'])}/> : null}

            {/*<PlaceCardActionItem id={props.index === 0 ? 'save-step' : `save${props.index}`} icon={<SaveOutlined sx={{color: appBarColor,}}/>} title={'Save'}*/}
            {/*                     action={() => props.saveToList(place)}/>*/}

            {
                place.lat !== '' ?
                    <PlaceCardActionItem id={props.index === 0 ? 'directions-step' : `save${props.index}`}
                                         icon={<Directions sx={{color: appBarColor,}}/>} title={'Let\'s Go!'}
                                         action={() => goToMap()}/> : null
            }


            {/*<PlaceCardActionItem id={props.index === 0 ? 'watch-step' : `save${props.index}`}*/}
            {/*                     icon={<Tv sx={{color: appBarColor,}}/>} title={'Watch Review'}*/}
            {/*                     action={() => watchVideo()}/>*/}

            {/*{place['website_url'] ? <PlaceCardActionItem id={`url${props.index}`}*/}
            {/*                                             icon={<AirplaneTicket sx={{color: appBarColor,}}/>} title={'Tickets'}*/}
            {/*                                             action={() => openWebsite(place['url'])}/> : null}*/}

            {place['phone'] ? <PlaceCardActionItem id={`call${props.index}`}
                                                         icon={<Call sx={{color: appBarColor,}}/>} title={'Call'}
                                                         action={() => callPlace()}/> : null}


        </Box>
    </Box>
}

export default ThingsToDoAction;
