export default () => {
    // eslint-disable-next-line no-restricted-globals
    self.addEventListener('message', (event) => {
        const { places, category, onVegasStrip, selectedLocation } = event.data;
        const result = filterPlaces(places, category, onVegasStrip, selectedLocation);
        // eslint-disable-next-line no-restricted-globals
        self.postMessage(result);
    });

    function filterPlaces(places, category, onVegasStrip, selectedLocation) {
        const locationStateMap = {
            'All': 'all',
            'Las Vegas': 'NV',
            'Arizona': 'AZ',
            'Atlanta': 'GA',
            'Bay Area': 'CA',
            'Chicago': 'IL',
            'Dallas': 'TX',
            'Detroit': 'MI',
            'Houston': 'TX',
            'Los Angeles': 'CA',
            'Louisiana': 'LA',
            'Miami': 'FL',
            'New York': 'NY',
            // Add any other location-state mappings as needed
        };

        const selectedStateAbbrev = locationStateMap[selectedLocation]?.toLowerCase();

        return places.filter(place => {
            if (onVegasStrip && !place.on_vegas_strip) {
                return false;
            }

            const stateAbbrev = place.state?.toLowerCase();
            if (selectedStateAbbrev !== 'all' && stateAbbrev !== selectedStateAbbrev) {
                return false;
            }

            const city = place.city?.toLowerCase();
            if (selectedLocation.toLowerCase() === 'dallas' && (city === 'houston' || city === 'pearland')) {
                return false;
            } else if (selectedLocation.toLowerCase() === 'houston' && ['dallas', 'frisco', 'farmers branch', 'desoto', 'garland', 'rowlett'].includes(city)) {
                return false;
            } else if (selectedLocation.toLowerCase() === 'los angeles' && !['los angeles', 'inglewood', 'burbank', 'santa monica', 'alhambra', 'fullerton'].includes(city)) {
                return false;
            } else if (selectedLocation.toLowerCase() === 'bay area' && !['oakland', 'san francisco', 'sf'].includes(city)) {
                return false;
            }

            if (category.toLowerCase() === 'all') {
                return true;
            }

            return place.place_category.some(placeCategory => placeCategory.category_id.name.toLowerCase() === category.toLowerCase());
        });
    }
};
