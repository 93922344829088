import {AppBar, Button, IconButton, Toolbar, Typography, useMediaQuery} from '@mui/material';
import Constant from './Constants';
import MenuIcon from '@mui/icons-material/Menu';
import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {setMobileOpen} from './redux/reducers/navSlice';
import {signInWithGoogle, signOut} from './redux/reducers/authSlice';
import {Steps} from 'intro.js-react';
import {useLocation} from 'react-router-dom';

const FoodAppBar = () => {
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const drawerWidth = 240;

    const nav = useSelector(state => state.nav);
    const user = useSelector(state => state.auth.user);
    const places = useSelector(state => state.places.places);

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const [enabled, setEnabled] = useState(false)
    const [initialStep, setInitialStep] = useState(0)


    let location = useLocation();

    useEffect(() => {
        if (location.pathname === '/keith-lee-food-list' || window.location.pathname === '/') {
            setSteps(stepsHome)
        }

    }, [location]);



    const stepsHome = [
        {
            element: '#vegas-strip-filter',
            intro: 'Click here to filter the places to only show places on the Las Vegas strip',
            position: 'right',
        },
        {
            element: '#category-filter',
            intro: 'Click here to filter the places by category',
            position: 'right',
        },
        {
            element: '#name-step',
            intro: 'Click the name to go to the places profile',
            position: 'right',
        },
        {
            element: '#web-step',
            intro: 'Click here to visit the website' ,
            position: 'right',
        },
        {
            element: '#directions-step',
            intro: 'Click here to get driving directions',
            position: 'right',
        },
        {
            element: '#watch-step',
            intro: 'Click here to watch Keith Lee\'s food review',
            position: 'right',
        },
        {
            element: '#more-step',
            intro: 'Click here to get more details about this place and perform other actions',
            position: 'right',
        },

    ];

    const stepsMap = [
        {
            element: '#vegas-strip-filter',
            intro: 'Click here to filter the places to only show places on the Las Vegas strip',
            position: 'right',
        },
        {
            element: '#category-filter',
            intro: 'Click here to filter the places by category',
            position: 'right',
        },
    ];

    const [steps, setSteps] = useState(stepsHome)

    const onExit = () => {
        setEnabled(false)
    }

    const handleAuth = () => {
        if (user) {
            dispatch(signOut())
        } else {
            dispatch(signInWithGoogle())
        }
    }

    const handleDrawerToggle = () => {
        dispatch(setMobileOpen(!nav.mobileOpen))
    }

    const startCardSteps = () => {
        setEnabled(true)
    }

    return (


        <AppBar
            sx={{
                width: {sm: `calc(100% - ${drawerWidth}px)`},
                ml: {sm: `${drawerWidth}px`},
                backgroundColor: Constant.appBarColor
            }}
            position="fixed"
        >

            <Steps
                enabled={enabled}
                steps={steps}
                initialStep={initialStep}
                onExit={onExit}
            />
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{mr: 2, display: {sm: 'none'}}}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography variant="h7" component="div" sx={{flexGrow: 1, fontWeight: 'bold'}}>
                    Keith Lee Food
                </Typography>
                {
                    places.length > 0 && (location.pathname === '/keith-lee-food-list' || location.pathname === '/') ?
                        <Button onClick={startCardSteps} color="inherit">
                            Help
                        </Button> : null
                }
            </Toolbar>
        </AppBar>
    );
}

export default FoodAppBar;
