import React from 'react'
import {Route, Routes} from 'react-router'
import Places from './Places';
import FoodMap from './FoodMap';
import About from './About';
import FoodList from './FoodList';
import ListMap from './ListMap';
import ListPlaces from './ListPlaces';
import Place from './Place';
import PlacesOnStrip from './PlacesOnStrip';
import FoodTour from './FoodTour';
import CaliFoodTour from './CaliFoodTour';
import LouisianaFoodTour from './LouisianaFoodTour';
import MichiganFoodTour from './MichiganFoodTour';
import NewYorkFoodTour from './NewYorkFoodTour';
import ChicagoFoodTour from './ChicagoFoodTour';
import GeorgiaFoodTour from './GeorgiaFoodTour';
import HoustonFoodTour from './HoustonFoodTour';
import BayFoodTour from './BayFoodTour';
import DallasFoodTour from './DallasFoodTour';
import ArizonaFoodTour from './ArizonaFoodTour';
import MenuGrid from './MenuGrid';
import HawaiiFoodTour from './HawaiiFoodTour';
import TorontoFoodTour from './TorontoFoodTour';
import MiamiFoodTour from './MiamiFoodTour';
import BatonRougeFoodTour from './BatonRougeFoodTour';
import ThingsToDo from './ThingsToDo';
import DestinationThingsToDo from './DestinationThingsToDo';

const FoodRouter = () => {
    return (
        <Routes>
            <Route exact path='/' element={<Places/>}/>
            <Route exact path='/keith-lee-food-home' element={<MenuGrid/>}/>
            <Route exact path='/keith-lee-food-list' element={<Places/>}/>
            <Route exact path='/about-keith-lee-food-app' element={<About/>}/>
            <Route exact path='/about' element={<About/>}/>
            <Route exact path="/place/:id" element={<Place/>}/>
            <Route exact path="/listmap/:id" element={<ListMap/>}/>
            <Route exact path="/listplaces/:id" element={<ListPlaces/>}/>
            <Route exact path='/keith-lee-food-map' element={<FoodMap/>}/>
            <Route exact path='/keith-lee-rated-map' element={<FoodMap/>}/>
            <Route exact path='/keith-lee-food-tour' element={<FoodTour/>}/>
            <Route exact path='/things-to-do-in/:destinationId' element={<DestinationThingsToDo/>}/>
            {/*<Route exact path='/keith-lee-food-tour/:uppercaseState' element={<LocalFoodTour/>}/>*/}
            <Route exact path='/keith-lee-arizona-food-tour' element={<ArizonaFoodTour/>}/>
            <Route exact path='/keith-lee-atlanta-food-tour' element={<GeorgiaFoodTour/>}/>
            <Route exact path='/keith-lee-baton-rouge-food-tour' element={<BatonRougeFoodTour/>}/>
            <Route exact path='/keith-lee-los-angeles-food-tour' element={<CaliFoodTour/>}/>
            <Route exact path='/keith-lee-chicago-food-tour' element={<ChicagoFoodTour/>}/>
            <Route exact path='/keith-lee-louisiana-food-tour' element={<LouisianaFoodTour/>}/>
            <Route exact path='/keith-lee-miami-food-tour' element={<MiamiFoodTour/>}/>
            <Route exact path='/keith-lee-hawaii-food-tour' element={<HawaiiFoodTour/>}/>
            <Route exact path='/keith-lee-toronto-food-tour' element={<TorontoFoodTour/>}/>
            <Route exact path='/keith-lee-detroit-michigan-food-tour' element={<MichiganFoodTour/>}/>
            <Route exact path='/keith-lee-new-york-food-tour' element={<NewYorkFoodTour/>}/>
            <Route exact path='/keith-lee-houston-texas-food-tour' element={<HoustonFoodTour/>}/>
            <Route exact path='/keith-lee-bay-area-food-tour' element={<BayFoodTour/>}/>
            <Route exact path='/keith-lee-dallas-tx-food-tour' element={<DallasFoodTour/>}/>
            <Route exact path='/keith-lee-reviews-on-the-strip' element={<PlacesOnStrip/>}/>
            <Route exact path='/foodmap' element={<FoodMap/>}/>
            <Route exact path="/things-to-do/:cityId" element={<ThingsToDo/>}/>
            <Route exact path='/my-food-lists' element={<FoodList/>}/>
        </Routes>
    )
}

export default FoodRouter
