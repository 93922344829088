import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    ListItemAvatar,
    Typography,
    useMediaQuery
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {styled, useTheme} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {deleteMyAccount, getUserLists, getUserPlaces, signInWithGoogle, signOut} from './redux/reducers/authSlice';
import {
    ArrowDownwardRounded,
    Delete,
    FastfoodOutlined,
    ListAltOutlined,
    Logout,
    MapOutlined
} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import AlertDialog from './AlertDialog';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

const Demo = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px'
}));
const FoodList = () => {

    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertIsLogout, setAlertIsLogout] = useState(false)

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const foodList = useSelector(state => state.auth.foodLists);
    const user = useSelector(state => state.auth.user);

    useEffect(() => {

        if (user && foodList.length === 0) {
            dispatch(getUserLists());
        }

    }, [user]);

    const showListMap = (list) => {
        navigate(`/listmap/${list['slug']}`)
    }

    const showListPlaces = (list) => {
        navigate(`/listplaces/${list['slug']}`)
    }

    const confirmDelete = () => {
        setAlertTitle('Delete Account')
        setAlertMessage('Are you sure you want to delete account? This action cannot be undone.')
        setAlertIsLogout(false)
        setAlertOpen(true)
    }


    const confirmLogout = () => {
        setAlertTitle('Logout')
        setAlertIsLogout(true)
        setAlertMessage('Are you sure you want to log out of your account?')
        setAlertOpen(true)
    }


    const handleAlertOpen = (didAccept) => {
        setAlertOpen(false)
        if (didAccept) {
            if(alertIsLogout){
                dispatch(signOut())
            }
            else{
                dispatch(deleteMyAccount())
            }
            navigate('/')
        }
    }


    return (
        <Box
            sx={{
                marginLeft: matches ? '20px' : '250px',
                marginRight: matches ? '20px' : '250px',
                marginTop: '20px',
                height: '100vh',
            }}
        >

            {alertOpen ?
                <AlertDialog open={alertOpen} title={alertTitle}
                             message={alertMessage}
                             handleClickOpen={handleAlertOpen}></AlertDialog> :
                null}


            <Box
                sx={{
                    margin: '8px',
                }}
            >
                <Card>
                    <CardContent>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardRounded/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>User Actions</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Typography variant="h7" component="div">
                                        Log Out
                                    </Typography>
                                    <IconButton onClick={() => confirmLogout()}>
                                        <Logout/>
                                    </IconButton>
                                </Box>

                                <Box>
                                    <Typography variant="h7" component="div">
                                        Delete Account
                                    </Typography>
                                    <IconButton onClick={() => confirmDelete()}>
                                        <Delete/>
                                    </IconButton>
                                </Box>

                            </AccordionDetails>

                        </Accordion>

                    </CardContent>
                </Card>

            </Box>

            {foodList.length !== 0 ?
                <Grid item xs={12} md={6}>
                    {/*<Typography sx={{mt: 4, mb: 2}} variant="h6" component="div">*/}
                    {/*    Avatar with text and icon*/}
                    {/*</Typography>*/}
                    <Demo>
                        <List dense={dense}>
                            {
                                foodList.map(list => (
                                    <ListItem
                                        // secondaryAction={
                                        //     <IconButton edge="end" aria-label="delete">
                                        //         <DeleteIcon/>
                                        //     </IconButton>
                                        // }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FastfoodOutlined/>
                                            </Avatar>
                                        </ListItemAvatar>

                                        <ListItemText
                                            primary={list.title}
                                            secondary={secondary ? 'Secondary text' : null}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography variant="h7" component="div">
                                                List
                                            </Typography>
                                            <IconButton onClick={() => showListPlaces(list)}>
                                                <ListAltOutlined/>
                                            </IconButton>
                                        </Box>
                                        {/*<Box*/}
                                        {/*    sx={{*/}
                                        {/*        display: 'flex',*/}
                                        {/*        flexDirection: 'column',*/}
                                        {/*        alignItems: 'center',*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    <Typography variant="h7" component="div">*/}
                                        {/*        Map*/}
                                        {/*    </Typography>*/}
                                        {/*    <IconButton onClick={() => showListMap(list)}>*/}
                                        {/*        <MapOutlined/>*/}
                                        {/*    </IconButton>*/}
                                        {/*</Box>*/}
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Demo>
                </Grid>
                : <Box sx={{color: 'white'}}>You don't have any lists. Go to the home page to create one.</Box>
            }
        </Box>
    )

}
export default FoodList
